import React from "react";
import { withStyles } from "@material-ui/core/styles";

import { withTranslation } from "react-i18next";
import { compose } from "redux";

import Table from "components/Table/Table";
import GSearchActions from "components/GSearchActions";
import axios from "axios";
import Config from "config";

import {
  Dropdown,
  Statistic,
  Dimmer,
  Loader,
  Segment,
  Label,
} from "semantic-ui-react";

import Zoom from "@material-ui/core/Zoom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import PropTypes from "prop-types";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Pagination from "components/Pagination/Pagination.js";
import styles from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button";
import Input from "components/CustomInput/CustomInput.js";

// icons
import CloudDownload from "@material-ui/icons/CloudDownload";

import secureStorage from "libs/secureStorage";
// import ExportExcel from "components/ExportExcel";
import moment from "moment";
import _ from "lodash";

import { SalesType, DirectUserType, EndUsers } from "content/User/libs";

/**
 * Transition for Modal
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});
class Transaction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      extractedRows: [],
      header: [],
      footer: [],

      total: [],

      selectedEndUsers: [],
      endUser: [],

      selectedCas: [],
      ca: [],

      selectedSubcas: [],
      subca: [],

      selectedSalesDms: [],
      salesDm: [],

      selectedMitraDms: [],
      mitraDm: [],

      selectedMitraTls: [],
      mitraTl: [],

      selectedProducts: [],
      products: [],

      selectedCategories: [],
      categories: [],

      selectedPartners: [],
      partners: [],

      salesType: 0,

      user: secureStorage.getItem("user"),

      rowsPerPage: 15,
      currentPage: 1,
      totalPages: 0,
      totalData: 0,
      currentPageData: [],

      startDate: moment().startOf("day"),
      endDate: moment().endOf("day"),

      userTypes: [],

      keyword: "",

      // pendingShow : false,
      // failedShow : false,

      isLoading: false,
      submitDownloadDisabled: false,

      //   dialog
      showModal: false,
      modalTitle: "",
      modalMessage: "",
    };
    this.exportTransaction = React.createRef();
  }
  componentDidMount() {
    const user = secureStorage.getItem("user");
    const salesType = this.initSalesType();

    this.loadProduct();
    this.loadCategory();
    user.userTypeId === 1 && this.loadPartner();
    this.initUserTypes({ salesType });
    // this.loadTransaction({salesType})
  }
  initSalesType() {
    // ['DM 2 Levels','TL 3 Levels','H2H DM','H2H TL',"DP 3 Levels","H2H RF","FD 5 Levels","FT 5 Levels"]
    const user = secureStorage.getItem("user");
    let salesType = 0;
    if ([1, 6].includes(user.userTypeId)) salesType = 0;
    else if ([2, 3].includes(user.userTypeId)) salesType = 1;
    else if ([5].includes(user.userTypeId)) salesType = 2;
    else if ([8].includes(user.userTypeId)) salesType = 2;
    else if ([9, 10].includes(user.userTypeId)) salesType = 4;
    else if ([12].includes(user.userTypeId)) salesType = 5;
    else if ([14, 15, 16, 17].includes(user.userTypeId)) salesType = 6;
    else if ([19, 20, 21, 22].includes(user.userTypeId)) salesType = 7;
    else if ([24, 25].includes(user.userTypeId)) salesType = 8;
    else if ([26, 27, 28].includes(user.userTypeId)) salesType = 9;
    this.setState({ salesType });

    return salesType;
  }

  loadPartner() {
    axios
      .post(Config.ApiUrl + "/transaction/partner")
      .then((res) => {
        this.setState({ partners: res.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  loadProduct() {
    const selectedCategories = this.state.selectedCategories;

    const payload = {
      selectedCategories,
    };
    axios
      .post(Config.ApiUrl + "/transaction/product", payload)
      .then((res) => {
        this.setState({ products: res.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  loadCategory() {
    axios
      .post(Config.ApiUrl + "/transaction/category")
      .then((res) => {
        this.setState({ categories: res.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleDownload = async () => {
    this.setState({ submitDownloadDisabled: true });
    try {
      const user = secureStorage.getItem("user");
      const userTypes = this.state.userTypes;
      const users = _.flattenDeep(
        userTypes
          .filter((item) => item.id !== 1 && this.state[item.id])
          .map((item) => this.state[item.id])
      );

      const payload = {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        utcOffset: moment().utcOffset(),
        // header : params && params.header?params.header : this.state.header,
        // pendingShow : this.state.pendingShow,
        // failedShow : this.state.failedShow,
        //users payload
        parents: users.length > 0 ? users : [user],

        products:
          this.state.selectedProducts &&
          this.state.selectedProducts.length > 0 &&
          this.state.selectedProducts,
        categories:
          this.state.selectedCategories &&
          this.state.selectedCategories.length > 0 &&
          this.state.selectedCategories,
        partners:
          this.state.selectedPartners &&
          this.state.selectedPartners.length > 0 &&
          this.state.selectedPartners,
        salesType: this.state.salesType,
        keyword: this.state.keyword,
      };

      // console.log({ payload });
      const endpoint = `${Config.ApiUrl}/transaction/download`;
      const response = await axios.post(endpoint, payload, {
        responseType: "blob", // Untuk memastikan response adalah blob
        // timeout: 30000, // 30seconds
      });

      if (response.status === 200) {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: response.headers["content-type"] })
        );
        const a = document.createElement("a");
        a.href = url;

        a.setAttribute(
          "download",
          `Transaksi ${moment(this.state.startDate)
            .utcOffset("+07:00")
            .format("DD-MM-YYYY")} <-> ${moment(this.state.endDate)
            .utcOffset("+07:00")
            .format("DD-MM-YYYY")}`
        );
        document.body.appendChild(a);
        a.click();
        a.remove();
        this.setState({ submitDownloadDisabled: false, showModal: false });
      } else {
        this.setState({
          submitDownloadDisabled: false,
          showModal: true,
          modalTitle: "Failed",
          modalMessage: "Network failed",
        });
      }
    } catch (err) {
      console.log(err.message);
      this.setState({
        submitDownloadDisabled: false,
        showModal: true,
        modalTitle: "Failed",
        modalMessage: "Network failed",
      });
    }
  };

  loadTransaction = (params) => {
    const user = secureStorage.getItem("user");
    const userTypes = this.state.userTypes;
    const users = _.flattenDeep(
      userTypes
        .filter((item) => item.id !== 1 && this.state[item.id])
        .map((item) => this.state[item.id])
    );
    this.setState({ isLoading: true });
    const payload = {
      startDate:
        params && params.startDate ? params.startDate : this.state.startDate,
      endDate: params && params.endDate ? params.endDate : this.state.endDate,
      utcOffset: moment().utcOffset(),
      // header : params && params.header?params.header : this.state.header,
      // pendingShow : this.state.pendingShow,
      // failedShow : this.state.failedShow,
      //users payload
      parents: users.length > 0 ? users : [user],

      products:
        this.state.selectedProducts &&
        this.state.selectedProducts.length > 0 &&
        this.state.selectedProducts,
      categories:
        this.state.selectedCategories &&
        this.state.selectedCategories.length > 0 &&
        this.state.selectedCategories,
      partners:
        this.state.selectedPartners &&
        this.state.selectedPartners.length > 0 &&
        this.state.selectedPartners,
      salesType:
        params && params.salesType ? params.salesType : this.state.salesType,
      // pagination payload>>
      currentPage: params && params.currentPage ? params.currentPage : 1,
      rowsPerPage:
        params && params.rowsPerPage
          ? params.rowsPerPage
          : this.state.rowsPerPage,
      // <<pagination payload

      keyword: params && params.keyword ? params.keyword : this.state.keyword,
    };
    // console.log(users)
    axios
      .post(Config.ApiUrl + "/transaction", payload)
      .then((res) => {
        // console.log(res.data)
        const { t } = this.props;
        this.setState({
          extractedRows: res.data.extractedRows.data,
          total: res.data.extractedRows.footer,
          totalPages: res.data.totalPages,
          totalData: res.data.totalData,
          startDate: payload.startDate,
          endDate: payload.endDate,
          header: res.data.extractedRows.header.map((item) => t(item)),
          isLoading: false,
        });

        // this.exportTransaction.current.updateDataset(
        //   res.data.extractedRows.dataset
        // );
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };

  initUserTypes({ salesType }) {
    const user = secureStorage.getItem("user");
    axios
      .post(Config.ApiUrl + "/transaction/usertypes")
      .then((res) => {
        const userTypes = res.data;
        this.setState({ userTypes });
        this.loadUserDropdown({
          salesType,
          parents: [user],
          userTypes,
          parentTypeId: user.userTypeId,
          init: true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  loadUserDropdown(params) {
    const user = secureStorage.getItem("user");
    const userTypes = params.userTypes
      ? params.userTypes
      : this.state.userTypes;
    const salesType = params.salesType
      ? params.salesType
      : this.state.salesType;
    const payload = {
      salesType,
      parents: params.parents && params.parents,
    };
    // console.log(payload)
    axios
      .post(Config.ApiUrl + "/transaction/user", payload)
      .then((res) => {
        // console.log(params)
        let userTypeIdx = params.parentTypeId;
        if (params.init && user.userTypeId === 1)
          userTypeIdx = DirectUserType[salesType] - 1;
        // console.log(params.parentTypeId)
        // console.log(userTypes)
        this.setState({ [userTypes[userTypeIdx].name]: res.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getUserDropDown = (userTypeId) => {
    if (
      this.state.userTypes.length > 0 &&
      this.state[this.state.userTypes[userTypeId - 1].name]
    )
      return (
        <GridItem xl={4} lg={4} md={4} sm={4} xs={6}>
          <Dropdown
            style={{ marginTop: 25 }}
            placeholder={this.state.userTypes[userTypeId - 1].name}
            fluid
            multiple
            search
            selection
            onChange={(e, { value }) => {
              // console.log(value,userTypeId,this.state.userTypes)
              if (value.length === 0 && this.state.userTypes[userTypeId])
                this.setState({
                  [this.state.userTypes[userTypeId].name]: undefined,
                  [this.state.userTypes[userTypeId].id]: undefined,
                });
              if (EndUsers.indexOf(userTypeId) === -1 && value.length > 0)
                this.loadUserDropdown({
                  // salesType,
                  parents: this.state[
                    this.state.userTypes[userTypeId - 1].name
                  ].filter((item) => value.indexOf(item.id) !== -1),
                  // userTypes,
                  parentTypeId: userTypeId,
                });
              const val = this.state[this.state.userTypes[userTypeId - 1].name]
                .filter((item) => value.indexOf(item.id) !== -1)
                .map((item) => item);
              // console.log(val)
              this.setState({
                [this.state.userTypes[userTypeId - 1].id]: val,
              });
            }}
            options={this.state[this.state.userTypes[userTypeId - 1].name].map(
              (item, index) => ({
                key: item.id,
                text: item.name,
                value: item.id,
              })
            )}
          />
        </GridItem>
      );
  };
  clearUserDropDown = () => {
    this.state.userTypes.map((userType) => {
      this.state[userType.id] && this.setState({ [userType.id]: undefined });
      return userType;
    });
  };

  render() {
    const { classes, t } = this.props;
    const user = secureStorage.getItem("user");
    return (
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.contactContent}>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                <GridContainer justify="center">
                  <GridItem
                    xl={2}
                    lg={2}
                    md={2}
                    sm={12}
                    xs={12}
                    className={classes.grid}
                  >
                    <Label size="huge">{t("transaction").toUpperCase()}</Label>
                  </GridItem>
                  <GridItem xl={10} lg={10} md={10} sm={12} xs={12}>
                    <GSearchActions
                      onChange={({ startDate, endDate }) =>
                        this.setState({ startDate, endDate })
                      }
                      onSubmit={this.loadTransaction}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer justify="center">
                  <GridItem xl={3} lg={3} md={3} sm={12} xs={12}>
                    <Dropdown
                      style={{ marginTop: 25 }}
                      placeholder={t("category")}
                      fluid
                      multiple
                      search
                      selection
                      value={this.state.selectedCategories}
                      onChange={(e, { value }) => {
                        this.setState(
                          {
                            selectedCategories: value,
                            selectedProducts: [],
                          },
                          () => this.loadProduct()
                        );
                      }}
                      options={this.state.categories.map((item, index) => ({
                        key: item.id,
                        text: item.name,
                        value: item.id,
                      }))}
                    />
                  </GridItem>
                  <GridItem xl={3} lg={3} md={3} sm={12} xs={12}>
                    <Dropdown
                      style={{ marginTop: 25 }}
                      placeholder="ITEM"
                      fluid
                      multiple
                      search
                      selection
                      value={this.state.selectedProducts}
                      onChange={(e, { value }) => {
                        this.setState({
                          selectedProducts: value,
                          selectedCategories: [],
                        });
                      }}
                      options={this.state.products.map((item, index) => ({
                        key: item.id,
                        text: item.name,
                        value: item.id,
                      }))}
                    />
                  </GridItem>
                  {user.userTypeId === 1 && (
                    <GridItem xl={3} lg={3} md={3} sm={12} xs={12}>
                      <Dropdown
                        style={{ marginTop: 25 }}
                        placeholder="Partner"
                        fluid
                        multiple
                        search
                        selection
                        value={this.state.selectedPartners}
                        onChange={(e, { value }) => {
                          this.setState({
                            selectedPartners: value,
                          });
                        }}
                        options={this.state.partners.map((item, index) => ({
                          key: item.id,
                          text: item.name,
                          value: item.id,
                        }))}
                      />
                    </GridItem>
                  )}
                  {user.userTypeId === 1 && (
                    <GridItem xl={3} lg={3} md={3} sm={12} xs={12}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="salesType-select"
                          className={classes.selectLabel}
                        >
                          Sales Type
                        </InputLabel>
                        <Select
                          MenuProps={{ className: classes.selectMenu }}
                          classes={{ select: classes.select }}
                          value={this.state.salesType}
                          onChange={(e) => {
                            this.setState({
                              salesType: e.target.value,
                            });
                            this.clearUserDropDown();
                            this.loadUserDropdown({
                              salesType: e.target.value,
                              parents: [user],
                              // userTypes,
                              parentTypeId: user.userTypeId,
                              init: true,
                            });
                          }}
                          inputProps={{ name: "salesType", id: "salesType" }}
                        >
                          <MenuItem
                            disabled
                            classes={{ root: classes.selectMenuItem }}
                            value={""}
                          >
                            Sales Type
                          </MenuItem>
                          {SalesType.map((item, index) => (
                            <MenuItem
                              key={index}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value={index}
                            >
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                  )}
                </GridContainer>
                <GridContainer justify="center">
                  {/* const SalesType = ['DM 2 Levels','TL 3 Levels','H2H DM','H2H TL',"DP 3 Levels","H2H RF","FD 5 Levels","FT 5 Levels"] */}
                  {user.userTypeId === 1 &&
                    this.state.salesType === 0 &&
                    this.getUserDropDown(DirectUserType[0])}
                  {[1, 6].includes(user.userTypeId) &&
                    [0].includes(this.state.salesType) &&
                    this.getUserDropDown(7)}

                  {user.userTypeId === 1 &&
                    this.state.salesType === 1 &&
                    this.getUserDropDown(DirectUserType[1])}
                  {[1, 2].includes(user.userTypeId) &&
                    [1].includes(this.state.salesType) &&
                    this.getUserDropDown(3)}
                  {[1, 2, 3].includes(user.userTypeId) &&
                    [1].includes(this.state.salesType) &&
                    this.getUserDropDown(4)}

                  {user.userTypeId === 1 &&
                    this.state.salesType === 2 &&
                    this.getUserDropDown(DirectUserType[2])}
                  {user.userTypeId === 1 &&
                    this.state.salesType === 3 &&
                    this.getUserDropDown(DirectUserType[3])}

                  {user.userTypeId === 1 &&
                    this.state.salesType === 4 &&
                    this.getUserDropDown(DirectUserType[4])}
                  {[1, 9].includes(user.userTypeId) &&
                    [4].includes(this.state.salesType) &&
                    this.getUserDropDown(10)}
                  {[1, 9, 10].includes(user.userTypeId) &&
                    [4].includes(this.state.salesType) &&
                    this.getUserDropDown(11)}

                  {user.userTypeId === 1 &&
                    this.state.salesType === 5 &&
                    this.getUserDropDown(DirectUserType[5])}
                  {[1, 12].includes(user.userTypeId) &&
                    [5].includes(this.state.salesType) &&
                    this.getUserDropDown(13)}

                  {user.userTypeId === 1 &&
                    this.state.salesType === 6 &&
                    this.getUserDropDown(DirectUserType[6])}
                  {[1, 14].includes(user.userTypeId) &&
                    [6].includes(this.state.salesType) &&
                    this.getUserDropDown(15)}
                  {[1, 14, 15].includes(user.userTypeId) &&
                    [6].includes(this.state.salesType) &&
                    this.getUserDropDown(16)}
                  {[1, 14, 15, 16].includes(user.userTypeId) &&
                    [6].includes(this.state.salesType) &&
                    this.getUserDropDown(17)}
                  {[1, 14, 15, 16, 17].includes(user.userTypeId) &&
                    [6].includes(this.state.salesType) &&
                    this.getUserDropDown(18)}

                  {user.userTypeId === 1 &&
                    this.state.salesType === 7 &&
                    this.getUserDropDown(DirectUserType[7])}
                  {[1, 19].includes(user.userTypeId) &&
                    [7].includes(this.state.salesType) &&
                    this.getUserDropDown(20)}
                  {[1, 19, 20].includes(user.userTypeId) &&
                    [7].includes(this.state.salesType) &&
                    this.getUserDropDown(21)}
                  {[1, 19, 20, 21].includes(user.userTypeId) &&
                    [7].includes(this.state.salesType) &&
                    this.getUserDropDown(22)}
                  {[1, 19, 20, 21, 22].includes(user.userTypeId) &&
                    [7].includes(this.state.salesType) &&
                    this.getUserDropDown(23)}

                  {user.userTypeId === 1 &&
                    this.state.salesType === 8 &&
                    this.getUserDropDown(DirectUserType[8])}
                  {[1, 24].includes(user.userTypeId) &&
                    [8].includes(this.state.salesType) &&
                    this.getUserDropDown(25)}

                  {user.userTypeId === 1 &&
                    this.state.salesType === 9 &&
                    this.getUserDropDown(DirectUserType[9])}
                  {[1, 26].includes(user.userTypeId) &&
                    [9].includes(this.state.salesType) &&
                    this.getUserDropDown(27)}
                  {[1, 26, 27].includes(user.userTypeId) &&
                    [9].includes(this.state.salesType) &&
                    this.getUserDropDown(28)}
                  {[1, 26, 27, 28].includes(user.userTypeId) &&
                    [9].includes(this.state.salesType) &&
                    this.getUserDropDown(29)}
                </GridContainer>
                {/* Keyword */}
                <GridContainer justify="center">
                  <GridItem xl={4} lg={4} md={4} sm={4} xs={4}>
                    <Input
                      labelText={t("search")}
                      id="keyword"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: "IDPEL / TRID / REFID",
                        value: this.state.keyword,
                        onChange: (e) => {
                          this.setState({ keyword: e.target.value });
                        },
                        onKeyPress: (e) => {
                          if (e.key === "Enter") {
                            this.setState({ keyword: e.target.value });
                            this.loadTransaction({ keyword: e.target.value });
                          }
                        },
                      }}
                    />
                  </GridItem>
                </GridContainer>
                {this.state.salesType === 1 && (
                  <GridContainer justify="center"></GridContainer>
                )}
              </GridItem>

              <GridItem xl={6} lg={6} md={6} sm={6} xs={6}>
                <Statistic size="tiny">
                  <Statistic.Value>
                    {this.state.totalData ? this.state.totalData : 0}
                  </Statistic.Value>
                  <Statistic.Label>Record(s)</Statistic.Label>
                </Statistic>
              </GridItem>
              <GridItem
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={6}
                style={{ textAlign: "right" }}
              >
                <Button
                  color="success"
                  variant="contained"
                  startIcon={<CloudDownload />}
                  onClick={this.handleDownload}
                  disabled={this.state.submitDownloadDisabled}
                  // disabled={true}
                >
                  Download File
                </Button>
                {/* <ExportExcel
                  title={`DOWNLOAD ${moment().format("YYYY-MM-DD HH:mm")}`}
                  filename={`GIN Trx Report ${moment().format(
                    "YYYY-MM-DD HH:mm"
                  )}`}
                  ref={this.exportTransaction}
                /> */}

                {/* <Checkbox label='Pending' onClick={(e,{checked})=>this.setState({pendingShow:checked})} style={{marginLeft:20}} />
								<Checkbox label='Failed' onClick={(e,{checked})=>this.setState({failedShow:checked})} style={{marginLeft:20}} /> */}
              </GridItem>
              <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                <Segment vertical>
                  <Dimmer active={this.state.isLoading} inverted>
                    <Loader />
                  </Dimmer>

                  <Table
                    togglePreview
                    sortable
                    striped
                    tableHead={this.state.header}
                    tableData={this.state.extractedRows}
                    tableFoot={this.state.total}
                    textAlignCells={[
                      "center",
                      ..._.times(
                        this.state.header.length - 3 - 6,
                        (n) => "right"
                      ),
                    ]}
                    textAlignForCells={[
                      0,
                      ..._.times(
                        this.state.header.length - 3 - 6,
                        (n) => n + 6
                      ),
                    ]}
                    textAlignHeaders={[
                      "center",
                      ..._.times(
                        this.state.header.length - 3 - 6,
                        (n) => "right"
                      ),
                    ]}
                    textAlignForHeaders={[
                      0,
                      ..._.times(
                        this.state.header.length - 3 - 6,
                        (n) => n + 6
                      ),
                    ]}
                    textAlignFooters={[
                      "center",
                      ..._.times(
                        this.state.header.length - 3 - 6,
                        (n) => "right"
                      ),
                    ]}
                    textAlignForFooters={[
                      0,
                      ..._.times(
                        this.state.header.length - 3 - 6,
                        (n) => n + 6
                      ),
                    ]}
                    rawData={this.state.extractedRows}
                    onSort={(data) => this.setState({ extractedRows: data })}
                  />
                </Segment>
              </GridItem>
              <GridItem
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className={classes.grid}
              >
                <div className={classes.justifyContentCenter}>
                  <Pagination
                    totalButton={7}
                    totalPages={this.state.totalPages}
                    totalData={this.state.totalData}
                    currentPage={this.state.currentPage}
                    onChangePage={(currentPage) => {
                      if (currentPage !== this.state.currentPage) {
                        this.setState({ currentPage });
                        this.loadTransaction({ currentPage });
                      }
                    }}
                    // pages={[
                    // 	...this.paginationButton(),
                    // ]}
                    rowsPerPage={[5, 15, 25, "All"]}
                    // rowsPerPage={[5, 15, 25]}
                    defaultRowsPerPage={15}
                    onChangeRowsPerPage={(value) => {
                      this.setState({ rowsPerPage: value, currentPage: 1 });
                      this.loadTransaction({
                        rowsPerPage: value,
                        currentPage: 1,
                      });
                    }}
                  />
                </div>
              </GridItem>
            </GridContainer>

            {/* Dialog */}
            <Dialog
              classes={{
                root: classes.modalRoot,
                paper: classes.modal,
              }}
              open={this.state.showModal}
              TransitionComponent={Transition}
              keepMounted
              //onClose={() => this.setState({showModal: false})}
              aria-labelledby="classic-modal-slide-title"
              aria-describedby="classic-modal-slide-description"
            >
              <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <h4 className={classes.modalTitle}>{this.state.modalTitle}</h4>
              </DialogTitle>
              <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}
              >
                <p>{this.state.modalMessage}</p>
              </DialogContent>
              <DialogActions className={classes.modalFooter}>
                <Button
                  onClick={() => this.setState({ showModal: false })}
                  color="success"
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
}
Transaction.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withTranslation(), withStyles(styles))(Transaction);
