import React from "react";
import { withStyles } from "@material-ui/core/styles";

import { withTranslation } from "react-i18next";
import { compose } from "redux";

import GSearchActions from "components/GSearchActions";
import axios from "axios";
import Config from "config";
import { fnumber } from "components/Helper";

import {
  Dropdown,
  Statistic,
  Dimmer,
  Loader,
  Segment,
  Label,
  Table,
  Button,
  Icon,
} from "semantic-ui-react";

import PropTypes from "prop-types";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Pagination from "components/Pagination/Pagination.js";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import style from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";

import moment from "moment";

import _ from "lodash";

import secureStorage from "libs/secureStorage";

import ExportExcel from "components/ExportExcel";

import { SalesType, DirectUserType, EndUsers } from "content/User/libs";

const styles = {
  ...style,
  oddRow: {
    backgroundColor: "#e8f4f7",
  },
  evenRow: {
    backgroundColor: "#cbe3ee",
  },
  tableResponsive: {
    minHeight: "0.1%",
    overflowX: "auto",
    // overflowY: 'scroll'
  },
  table: {
    marginBottom: "0",
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "transparent",
    borderSpacing: "0",
    borderCollapse: "collapse",
    overflow: "auto",
    "& > tbody > tr, & > thead > tr, & > tfoot > tr,": {
      height: "auto",
    },
  },
};

class TransactionByProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      total: "",

      // total : [],
      header: [],

      selectedEndUsers: [],
      endUser: [],

      selectedProducts: [],
      products: [],

      selectedPartners: [],
      partners: [],

      selectedCategories: [],
      categories: [],

      groups: [],
      groupBy: "user",

      salesType: 0,

      user: secureStorage.getItem("user"),

      rowsPerPage: 15,
      currentPage: 1,
      totalPages: 0,
      totalData: 0,
      currentPageData: [],

      startDate: moment().startOf("days"),
      endDate: moment().endOf("days"),

      userTypes: [],

      isLoading: false,
    };
    this.exportTransaction = React.createRef();
  }
  componentDidMount() {
    const user = secureStorage.getItem("user");
    const salesType = this.initSalesType();

    const { groups, groupBy } = this.initGroups(salesType);

    this.initUserTypes({ salesType });
    user.userTypeId === 1 && this.loadPartner();
    // this.loadTransaction({groups,groupBy,salesType})
  }
  initGroups(salesType) {
    const user = secureStorage.getItem("user");
    let groups = [];
    if ([1].indexOf(user.userTypeId) !== -1) {
      switch (salesType) {
        case 0:
          groups = ["user", "sldm", "self"];
          break;
        case 1:
        case 4:
          groups = ["user", "subca", "ca", "self"];
          break;
        case 5:
          groups = ["user", "borf", "self"];
          break;
        case 2:
        case 3:
          groups = ["user", "self"];
          break;
        case 6:
        case 7:
          groups = ["user", "subca2", "subca1", "ca", "mitra", "self"];
          break;
        default:
      }
    } else if ([2, 9].indexOf(user.userTypeId) !== -1)
      groups = ["user", "subca", "self"];
    else if ([3, 6, 10, 12, 17, 22].indexOf(user.userTypeId) !== -1)
      groups = ["user", "self"];
    else if ([14, 19].indexOf(user.userTypeId) !== -1)
      groups = ["user", "subca2", "subca1", "ca", "self"];
    else if ([15, 20].indexOf(user.userTypeId) !== -1)
      groups = ["user", "subca2", "subca1", "self"];
    else if ([16, 21].indexOf(user.userTypeId) !== -1)
      groups = ["user", "subca2", "subca1", "self"];
    else if ([17, 22].indexOf(user.userTypeId) !== -1)
      groups = ["user", "subca2", "self"];

    let groupBy;
    if (groups.length > 0) groupBy = groups[0];
    else groupBy = "self";
    this.setState({ groups, groupBy });
    return { groups, groupBy };
  }
  initSalesType() {
    // ['DM 2 Levels','TL 3 Levels','H2H DM','H2H TL',"DP 3 Levels","H2H RF","FD 5 Levels","FT 5 Levels"]
    const user = secureStorage.getItem("user");
    let salesType = 0;
    if ([1, 6].includes(user.userTypeId)) salesType = 0;
    else if ([2, 3].includes(user.userTypeId)) salesType = 1;
    else if ([5].includes(user.userTypeId)) salesType = 2;
    else if ([8].includes(user.userTypeId)) salesType = 2;
    else if ([9, 10].includes(user.userTypeId)) salesType = 4;
    else if ([12].includes(user.userTypeId)) salesType = 5;
    else if ([14, 15, 16, 17].includes(user.userTypeId)) salesType = 6;
    else if ([19, 20, 21, 22].includes(user.userTypeId)) salesType = 7;
    else if ([24, 25].includes(user.userTypeId)) salesType = 8;
    else if ([26, 27, 28].includes(user.userTypeId)) salesType = 9;
    this.setState({ salesType });
    return salesType;
  }

  initUserTypes({ salesType }) {
    const user = secureStorage.getItem("user");
    axios
      .post(Config.ApiUrl + "/transaction/usertypes")
      .then((res) => {
        const userTypes = res.data;
        this.setState({ userTypes });
        this.loadUserDropdown({
          salesType,
          parents: [user],
          userTypes,
          parentTypeId: user.userTypeId,
          init: true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  loadPartner() {
    axios
      .post(Config.ApiUrl + "/transaction/partner")
      .then((res) => {
        this.setState({ partners: res.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  loadTransaction = (params) => {
    const user = secureStorage.getItem("user");
    const userTypes = this.state.userTypes;
    const users = _.flattenDeep(
      userTypes
        .filter((item) => item.id !== 1 && this.state[item.id])
        .map((item) => this.state[item.id])
    );
    this.setState({ isLoading: true });
    // console.log(this.state.groupBy,this.state.groups.length)
    const groups = params && params.groups ? params.groups : this.state.groups;
    const payload = {
      startDate:
        params && params.startDate ? params.startDate : this.state.startDate,
      endDate: params && params.endDate ? params.endDate : this.state.endDate,
      utcOffset: moment().utcOffset(),
      // header : params && params.header?params.header : this.state.header,
      //users payload
      parents: users.length > 0 ? users : [user],

      products:
        this.state.selectedProducts &&
        this.state.selectedProducts.length > 0 &&
        this.state.selectedProducts,
      categories:
        this.state.selectedCategories &&
        this.state.selectedCategories.length > 0 &&
        this.state.selectedCategories,
      partners:
        this.state.selectedPartners &&
        this.state.selectedPartners.length > 0 &&
        this.state.selectedPartners,
      salesType:
        params && params.salesType ? params.salesType : this.state.salesType,
      groupBy:
        this.state.groupBy === "user" && groups.length === 2
          ? "directUser"
          : this.state.groupBy,
      // pagination payload>>
      currentPage: params && params.currentPage ? params.currentPage : 1,
      rowsPerPage:
        params && params.rowsPerPage
          ? params.rowsPerPage
          : this.state.rowsPerPage,
      // <<pagination payload
    };
    console.log(payload);
    axios
      .post(Config.ApiUrl + "/transaction/byproduct", payload)
      .then((res) => {
        console.log(res.data);
        const { t } = this.props;
        this.setState({
          rows: res.data.groupedUserSales,
          total: res.data.total,
          totalPages: res.data.totalPages,
          totalData: res.data.totalData,
          startDate: payload.startDate,
          endDate: payload.endDate,
          header: res.data.header.map((item) => t(item)),
          isLoading: false,
        });
        // res.data.dataSet.map(item=>console.log(item))
        this.exportTransaction.current.updateDataset(res.data.dataset);
        // console.log(res.data.sortedSales)
        // this.extractRows(res.data.sortedSales)
        //this.setTotal(res.data.sales)
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };

  loadUserDropdown(params) {
    const user = secureStorage.getItem("user");
    const userTypes = params.userTypes
      ? params.userTypes
      : this.state.userTypes;
    const salesType = params.salesType
      ? params.salesType
      : this.state.salesType;
    const payload = {
      salesType,
      parents: params.parents && params.parents,
    };
    // console.log(payload)
    axios
      .post(Config.ApiUrl + "/transaction/user", payload)
      .then((res) => {
        // console.log(params)
        let userTypeIdx = params.parentTypeId;
        if (params.init && user.userTypeId === 1)
          userTypeIdx = DirectUserType[salesType] - 1;
        // console.log(params.parentTypeId)
        // console.log(userTypes)
        this.setState({ [userTypes[userTypeIdx].name]: res.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getUserDropDown = (userTypeId) => {
    if (
      this.state.userTypes.length > 0 &&
      this.state[this.state.userTypes[userTypeId - 1].name]
    )
      return (
        <GridItem xl={4} lg={4} md={4} sm={4} xs={6}>
          <Dropdown
            style={{ marginTop: 25 }}
            placeholder={this.state.userTypes[userTypeId - 1].name}
            fluid
            multiple
            search
            selection
            onChange={(e, { value }) => {
              // console.log(value)
              if (value.length === 0 && this.state.userTypes[userTypeId])
                this.setState({
                  [this.state.userTypes[userTypeId].name]: undefined,
                  [this.state.userTypes[userTypeId].id]: undefined,
                });
              if (EndUsers.indexOf(userTypeId) === -1 && value.length > 0)
                this.loadUserDropdown({
                  // salesType,
                  parents: this.state[
                    this.state.userTypes[userTypeId - 1].name
                  ].filter((item) => value.indexOf(item.id) !== -1),
                  // userTypes,
                  parentTypeId: userTypeId,
                });
              const val = this.state[this.state.userTypes[userTypeId - 1].name]
                .filter((item) => value.indexOf(item.id) !== -1)
                .map((item) => item);
              // console.log(val)
              this.setState({
                [this.state.userTypes[userTypeId - 1].id]: val,
              });
            }}
            options={this.state[this.state.userTypes[userTypeId - 1].name].map(
              (item, index) => ({
                key: item.id,
                text: item.name,
                value: item.id,
              })
            )}
          />
        </GridItem>
      );
  };
  clearUserDropDown = () => {
    this.state.userTypes.map((userType) => {
      this.state[userType.id] && this.setState({ [userType.id]: undefined });
      return userType;
    });
  };

  render() {
    const { classes, t } = this.props;
    const user = secureStorage.getItem("user");
    return (
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.contactContent}>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                <GridContainer justify="center">
                  <GridItem
                    xl={2}
                    lg={2}
                    md={2}
                    sm={12}
                    xs={12}
                    className={classes.grid}
                  >
                    <Label size="huge">{t("transaction")}</Label>
                  </GridItem>
                  <GridItem xl={10} lg={10} md={10} sm={12} xs={12}>
                    <GSearchActions
                      onChange={({ startDate, endDate }) =>
                        this.setState({ startDate, endDate })
                      }
                      onSubmit={this.loadTransaction}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer justify="center">
                  {user.userTypeId === 1 && (
                    <GridItem xl={4} lg={4} md={4} sm={12} xs={12}>
                      <Dropdown
                        style={{ marginTop: 25 }}
                        placeholder="Partner"
                        fluid
                        multiple
                        search
                        selection
                        value={this.state.selectedPartners}
                        onChange={(e, { value }) => {
                          this.setState({
                            selectedPartners: value,
                          });
                        }}
                        options={this.state.partners.map((item, index) => ({
                          key: item.id,
                          text: item.name,
                          value: item.id,
                        }))}
                      />
                    </GridItem>
                  )}
                  <GridItem xl={4} lg={4} md={4} sm={12} xs={12}>
                    {this.state.groups.length > 0 && (
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="groupBy-select"
                          className={classes.selectLabel}
                        >
                          Group By
                        </InputLabel>
                        <Select
                          MenuProps={{ className: classes.selectMenu }}
                          classes={{ select: classes.select }}
                          value={this.state.groupBy}
                          onChange={(e) => {
                            console.log(e.target.value);
                            this.setState({ groupBy: e.target.value });
                          }}
                          inputProps={{
                            name: "groupBy",
                            id: "groupBy",
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                            value={""}
                          >
                            Group By
                          </MenuItem>
                          {this.state.groups.map((item, index) => (
                            <MenuItem
                              key={index}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value={
                                index === 0 ||
                                index === this.state.groups.length - 1
                                  ? item
                                  : index === this.state.groups.length - 2
                                  ? "direct"
                                  : index
                              }
                            >
                              {item.toUpperCase()}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </GridItem>
                  {user.userTypeId === 1 && (
                    <GridItem xl={4} lg={4} md={4} sm={12} xs={12}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="salesType-select"
                          className={classes.selectLabel}
                        >
                          Sales Type
                        </InputLabel>
                        <Select
                          MenuProps={{ className: classes.selectMenu }}
                          classes={{ select: classes.select }}
                          value={this.state.salesType}
                          onChange={(e) => {
                            this.setState({
                              salesType: e.target.value,
                            });
                            this.clearUserDropDown();
                            this.loadUserDropdown({
                              salesType: e.target.value,
                              parents: [user],
                              // userTypes,
                              parentTypeId: user.userTypeId,
                              init: true,
                            });
                          }}
                          inputProps={{ name: "salesType", id: "salesType" }}
                        >
                          <MenuItem
                            disabled
                            classes={{ root: classes.selectMenuItem }}
                            value={""}
                          >
                            Sales Type
                          </MenuItem>
                          {SalesType.map((item, index) => (
                            <MenuItem
                              key={index}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value={index}
                            >
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                  )}
                </GridContainer>
                <GridContainer justify="center">
                  {/* const SalesType = ['DM 2 Levels','TL 3 Levels','H2H DM','H2H TL',"DP 3 Levels","H2H RF","FD 5 Levels","FT 5 Levels"] */}
                  {user.userTypeId === 1 &&
                    this.state.salesType === 0 &&
                    this.getUserDropDown(DirectUserType[0])}
                  {[1, 6].includes(user.userTypeId) &&
                    [0].includes(this.state.salesType) &&
                    this.getUserDropDown(7)}

                  {user.userTypeId === 1 &&
                    this.state.salesType === 1 &&
                    this.getUserDropDown(DirectUserType[1])}
                  {[1, 2].includes(user.userTypeId) &&
                    [1].includes(this.state.salesType) &&
                    this.getUserDropDown(3)}
                  {[1, 2, 3].includes(user.userTypeId) &&
                    [1].includes(this.state.salesType) &&
                    this.getUserDropDown(4)}

                  {user.userTypeId === 1 &&
                    this.state.salesType === 2 &&
                    this.getUserDropDown(DirectUserType[2])}
                  {user.userTypeId === 1 &&
                    this.state.salesType === 3 &&
                    this.getUserDropDown(DirectUserType[3])}

                  {user.userTypeId === 1 &&
                    this.state.salesType === 4 &&
                    this.getUserDropDown(DirectUserType[4])}
                  {[1, 9].includes(user.userTypeId) &&
                    [4].includes(this.state.salesType) &&
                    this.getUserDropDown(10)}
                  {[1, 9, 10].includes(user.userTypeId) &&
                    [4].includes(this.state.salesType) &&
                    this.getUserDropDown(11)}

                  {user.userTypeId === 1 &&
                    this.state.salesType === 5 &&
                    this.getUserDropDown(DirectUserType[5])}
                  {[1, 12].includes(user.userTypeId) &&
                    [5].includes(this.state.salesType) &&
                    this.getUserDropDown(13)}

                  {user.userTypeId === 1 &&
                    this.state.salesType === 6 &&
                    this.getUserDropDown(DirectUserType[6])}
                  {[1, 14].includes(user.userTypeId) &&
                    [6].includes(this.state.salesType) &&
                    this.getUserDropDown(15)}
                  {[1, 14, 15].includes(user.userTypeId) &&
                    [6].includes(this.state.salesType) &&
                    this.getUserDropDown(16)}
                  {[1, 14, 15, 16].includes(user.userTypeId) &&
                    [6].includes(this.state.salesType) &&
                    this.getUserDropDown(17)}
                  {[1, 14, 15, 16, 17].includes(user.userTypeId) &&
                    [6].includes(this.state.salesType) &&
                    this.getUserDropDown(18)}

                  {user.userTypeId === 1 &&
                    this.state.salesType === 7 &&
                    this.getUserDropDown(DirectUserType[7])}
                  {[1, 19].includes(user.userTypeId) &&
                    [7].includes(this.state.salesType) &&
                    this.getUserDropDown(20)}
                  {[1, 19, 20].includes(user.userTypeId) &&
                    [7].includes(this.state.salesType) &&
                    this.getUserDropDown(21)}
                  {[1, 19, 20, 21].includes(user.userTypeId) &&
                    [7].includes(this.state.salesType) &&
                    this.getUserDropDown(22)}
                  {[1, 19, 20, 21, 22].includes(user.userTypeId) &&
                    [7].includes(this.state.salesType) &&
                    this.getUserDropDown(23)}

                  {user.userTypeId === 1 &&
                    this.state.salesType === 8 &&
                    this.getUserDropDown(DirectUserType[8])}
                  {[1, 24].includes(user.userTypeId) &&
                    [8].includes(this.state.salesType) &&
                    this.getUserDropDown(25)}

                  {user.userTypeId === 1 &&
                    this.state.salesType === 9 &&
                    this.getUserDropDown(DirectUserType[9])}
                  {[1, 26].includes(user.userTypeId) &&
                    [9].includes(this.state.salesType) &&
                    this.getUserDropDown(27)}
                  {[1, 26, 27].includes(user.userTypeId) &&
                    [9].includes(this.state.salesType) &&
                    this.getUserDropDown(28)}
                  {[1, 26, 27, 28].includes(user.userTypeId) &&
                    [9].includes(this.state.salesType) &&
                    this.getUserDropDown(29)}
                </GridContainer>
              </GridItem>
              <GridItem xl={6} lg={6} md={6} sm={6} xs={6}>
                <Statistic size="tiny">
                  <Statistic.Value>
                    {this.state.totalData ? this.state.totalData : 0}
                  </Statistic.Value>
                  <Statistic.Label>Record(s)</Statistic.Label>
                </Statistic>
              </GridItem>
              <GridItem
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={6}
                style={{ textAlign: "right", marginTop: 20 }}
              >
                <ExportExcel
                  title={`DOWNLOAD ${moment().format("YYYY-MM-DD HH:mm")}`}
                  filename={`GIN Trx Report By Item ${moment().format(
                    "YYYY-MM-DD HH:mm"
                  )}`}
                  ref={this.exportTransaction}
                />
              </GridItem>
              <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                <Segment vertical>
                  <Dimmer active={this.state.isLoading} inverted>
                    <Loader />
                  </Dimmer>
                  <div className={classes.tableResponsive}>
                    <Table selectable unstackable className={classes.table}>
                      <Table.Header>
                        <Table.Row>
                          {this.state.header.map((header, key) => (
                            <Table.HeaderCell key={key}>
                              {header}
                            </Table.HeaderCell>
                          ))}
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {_.orderBy(this.state.rows, "name").map(
                          (userItem, idx) => {
                            let qty = 0;
                            let partner = 0;
                            let revenue = 0;

                            let admin = 0;
                            let amount = 0;
                            let total = 0;
                            const sales = [...userItem.sales, "-"];
                            // console.log(sales)
                            const SaleFee = {};
                            const start =
                              user.userTypeId === 1
                                ? this.state.header.indexOf("Partner") + 1
                                : this.state.header.indexOf("Qty") + 1;
                            const end =
                              this.state.header.indexOf("Total Admin") - 2;
                            // console.log({start,end})
                            for (let key = start; key <= end; key++)
                              SaleFee[this.state.header[key]] = 0;

                            // console.log({SaleFee,sales})
                            // console.log({SaleFee:sales[0].SaleFee})
                            return _.orderBy(sales, "product").map(
                              (item, index) => {
                                if (index < userItem.sales.length) {
                                  qty += item.qty;
                                  partner += item.partner;
                                  revenue += item.revenue;

                                  Object.keys(SaleFee).map((key) => {
                                    SaleFee[key] += item.SaleFee[key]
                                      ? item.SaleFee[key]
                                      : 0;
                                    return key;
                                  });

                                  admin += item.admin * item.qty;
                                  amount += item.amount;
                                  total += item.admin * item.qty + item.amount;
                                }
                                if (index === 0) {
                                  return this.state["u" + idx] ? (
                                    <Table.Row
                                      key={idx + index}
                                      className={
                                        idx % 2 === 0
                                          ? classes.evenRow
                                          : classes.oddRow
                                      }
                                    >
                                      <Table.Cell>{idx + 1}</Table.Cell>
                                      <Table.Cell>
                                        {userItem.id.toString().padStart(6, 0)}
                                      </Table.Cell>
                                      <Table.Cell>{userItem.name}</Table.Cell>

                                      {/* { [0,1].indexOf(this.state.salesType)!==-1 && [1,2].indexOf(user.userTypeId)!==-1 && ['user','subca'].indexOf(this.state.groupBy) !==-1 && 
																<Table.Cell>{`${userItem.parent.name} (${userItem.parent.UserType.name})`}</Table.Cell>} */}

                                      {this.state.header.indexOf("Parent") !==
                                        -1 && (
                                        <Table.Cell>
                                          {this.getParent(userItem.Parent)}
                                        </Table.Cell>
                                      )}

                                      <Table.Cell>{item.product}</Table.Cell>

                                      <Table.Cell textAlign="right">
                                        {fnumber(item.admin)}
                                      </Table.Cell>
                                      <Table.Cell textAlign="right">
                                        {fnumber(item.qty)}
                                      </Table.Cell>
                                      {user.userTypeId === 1 ? (
                                        <Table.Cell textAlign="right">
                                          {fnumber(item.partner)}
                                        </Table.Cell>
                                      ) : null}

                                      {Object.keys(SaleFee).map((key) => (
                                        <Table.Cell key={key} textAlign="right">
                                          {fnumber(item.SaleFee[key])}
                                        </Table.Cell>
                                      ))}

                                      <Table.Cell textAlign="right">
                                        {fnumber(item.revenue)}
                                      </Table.Cell>
                                      <Table.Cell textAlign="right">
                                        {fnumber(item.admin * item.qty)}
                                      </Table.Cell>
                                      <Table.Cell textAlign="right">
                                        {fnumber(item.amount)}
                                      </Table.Cell>
                                      <Table.Cell textAlign="right">
                                        {fnumber(
                                          item.admin * item.qty + item.amount
                                        )}
                                      </Table.Cell>
                                    </Table.Row>
                                  ) : null;
                                } else if (
                                  index < userItem.sales.length &&
                                  this.state["u" + idx]
                                ) {
                                  return (
                                    <Table.Row
                                      key={idx + index}
                                      className={
                                        idx % 2 === 0
                                          ? classes.evenRow
                                          : classes.oddRow
                                      }
                                    >
                                      <Table.Cell></Table.Cell>
                                      <Table.Cell></Table.Cell>
                                      <Table.Cell></Table.Cell>

                                      {this.state.header.indexOf("Parent") !==
                                        -1 && <Table.Cell></Table.Cell>}

                                      <Table.Cell>{item.product}</Table.Cell>
                                      <Table.Cell textAlign="right">
                                        {fnumber(item.admin)}
                                      </Table.Cell>
                                      <Table.Cell textAlign="right">
                                        {fnumber(item.qty)}
                                      </Table.Cell>
                                      {user.userTypeId === 1 ? (
                                        <Table.Cell textAlign="right">
                                          {fnumber(item.partner)}
                                        </Table.Cell>
                                      ) : null}

                                      {Object.keys(SaleFee).map((key) => (
                                        <Table.Cell key={key} textAlign="right">
                                          {fnumber(item.SaleFee[key])}
                                        </Table.Cell>
                                      ))}

                                      <Table.Cell textAlign="right">
                                        {fnumber(item.revenue)}
                                      </Table.Cell>
                                      <Table.Cell textAlign="right">
                                        {fnumber(item.admin * item.qty)}
                                      </Table.Cell>
                                      <Table.Cell textAlign="right">
                                        {fnumber(item.amount)}
                                      </Table.Cell>
                                      <Table.Cell textAlign="right">
                                        {fnumber(
                                          item.admin * item.qty + item.amount
                                        )}
                                      </Table.Cell>
                                    </Table.Row>
                                  );
                                } else if (index === userItem.sales.length) {
                                  return (
                                    <Table.Row
                                      key={idx + index}
                                      style={{ fontWeight: "bold" }}
                                      className={
                                        idx % 2 === 0
                                          ? classes.evenRow
                                          : classes.oddRow
                                      }
                                    >
                                      <Table.Cell>
                                        {!this.state["u" + idx] && idx + 1}
                                      </Table.Cell>
                                      <Table.Cell>
                                        {!this.state["u" + idx] &&
                                          userItem.id.toString().padStart(6, 0)}
                                      </Table.Cell>
                                      <Table.Cell>
                                        {!this.state["u" + idx] &&
                                          userItem.name}
                                      </Table.Cell>

                                      {this.state.header.indexOf("Parent") !==
                                      -1 ? (
                                        !this.state["u" + idx] ? (
                                          <Table.Cell>
                                            {this.getParent(userItem.Parent)}
                                          </Table.Cell>
                                        ) : (
                                          <Table.Cell></Table.Cell>
                                        )
                                      ) : null}

                                      <Table.Cell textAlign="right">
                                        {!this.state["u" + idx] ? (
                                          <Button
                                            onClick={() =>
                                              this.setState({
                                                ["u" + idx]: true,
                                              })
                                            }
                                            size="mini"
                                            icon
                                            labelPosition="right"
                                            color="instagram"
                                          >
                                            Detail
                                            <Icon name="arrow down" />
                                          </Button>
                                        ) : (
                                          <Button
                                            onClick={() =>
                                              this.setState({
                                                ["u" + idx]: false,
                                              })
                                            }
                                            size="mini"
                                            icon
                                            labelPosition="right"
                                            color="orange"
                                          >
                                            Hide
                                            <Icon name="arrow up" />
                                          </Button>
                                        )}
                                      </Table.Cell>
                                      <Table.Cell textAlign="right">
                                        Total
                                      </Table.Cell>
                                      <Table.Cell textAlign="right">
                                        {fnumber(qty)}
                                      </Table.Cell>

                                      {user.userTypeId === 1 && (
                                        <Table.Cell textAlign="right">
                                          {fnumber(partner)}
                                        </Table.Cell>
                                      )}

                                      {Object.keys(SaleFee).map((key) => (
                                        <Table.Cell key={key} textAlign="right">
                                          {fnumber(SaleFee[key])}
                                        </Table.Cell>
                                      ))}

                                      <Table.Cell textAlign="right">
                                        {fnumber(revenue)}
                                      </Table.Cell>
                                      <Table.Cell textAlign="right">
                                        {fnumber(admin)}
                                      </Table.Cell>
                                      <Table.Cell textAlign="right">
                                        {fnumber(amount)}
                                      </Table.Cell>
                                      <Table.Cell textAlign="right">
                                        {fnumber(total)}
                                      </Table.Cell>
                                    </Table.Row>
                                  );
                                } else return null;
                              }
                            );
                          }
                        )}
                      </Table.Body>
                      {this.state.total && this.state.rows.length > 1 && (
                        <Table.Footer>
                          <Table.Row style={{ fontWeight: "bold" }}>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                            {this.state.header.indexOf("Parent") !== -1 && (
                              <Table.Cell></Table.Cell>
                            )}
                            <Table.Cell textAlign="right">
                              Grand Total
                            </Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell textAlign="right">
                              {fnumber(this.state.total.qty)}
                            </Table.Cell>
                            {user.userTypeId === 1 && (
                              <Table.Cell textAlign="right">
                                {fnumber(this.state.total.partner)}
                              </Table.Cell>
                            )}
                            {Object.keys(this.state.total.SaleFee).map(
                              (key) => (
                                <Table.Cell key={key} textAlign="right">
                                  {fnumber(this.state.total.SaleFee[key])}
                                </Table.Cell>
                              )
                            )}
                            <Table.Cell textAlign="right">
                              {fnumber(this.state.total.revenue)}
                            </Table.Cell>
                            <Table.Cell textAlign="right">
                              {fnumber(this.state.total.admin)}
                            </Table.Cell>

                            <Table.Cell textAlign="right">
                              {fnumber(this.state.total.amount)}
                            </Table.Cell>
                            <Table.Cell textAlign="right">
                              {fnumber(
                                this.state.total.admin + this.state.total.amount
                              )}
                            </Table.Cell>
                          </Table.Row>
                        </Table.Footer>
                      )}
                    </Table>
                  </div>
                </Segment>
              </GridItem>
              <GridItem
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className={classes.grid}
              >
                <div className={classes.justifyContentCenter}>
                  <Pagination
                    totalButton={7}
                    totalPages={this.state.totalPages}
                    totalData={this.state.totalData}
                    currentPage={this.state.currentPage}
                    onChangePage={(currentPage) => {
                      if (currentPage !== this.state.currentPage) {
                        this.setState({ currentPage });
                        this.loadTransaction({ currentPage });
                      }
                    }}
                    // pages={[
                    // 	...this.paginationButton(),
                    // ]}
                    rowsPerPage={[5, 15, 25, "All"]}
                    // rowsPerPage={[5, 15, 25]}
                    defaultRowsPerPage={15}
                    onChangeRowsPerPage={(value) => {
                      this.setState({ rowsPerPage: value, currentPage: 1 });
                      this.loadTransaction({
                        rowsPerPage: value,
                        currentPage: 1,
                      });
                    }}
                  />
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }
  getParent = (parent) => {
    const user = secureStorage.getItem("user");
    if (parent && parent.Parent && parent.userTypeId > user.userTypeId + 1) {
      return `${parent.userType}:${parent.name} - \n${this.getParent(
        parent.Parent
      )}`;
    } else {
      if (parent) return `${parent.userType}:${parent.name}`;
      else return "";
    }
  };
}
TransactionByProduct.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withTranslation(),
  withStyles(styles)
)(TransactionByProduct);
