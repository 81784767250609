import React from "react";
import { withStyles } from "@material-ui/core/styles";

import { withTranslation } from "react-i18next";
import { compose } from "redux";

import Table from "components/Table/Table";
import GSearchActions from "components/GSearchActions";
import axios from "axios";
import Config from "config";
import { fnumber } from "components/Helper";

import {
  Dropdown,
  Statistic,
  Dimmer,
  Loader,
  Segment,
  Label,
  Button,
  Checkbox,
  Confirm,
  Tab,
} from "semantic-ui-react";
import CButton from "components/CustomButtons/Button";

import PropTypes from "prop-types";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Pagination from "components/Pagination/Pagination.js";
import style from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Badge from "components/Badge/Badge.js";
import Input from "components/CustomInput/CustomInput.js";

import moment from "moment";
import secureStorage from "libs/secureStorage";
import _ from "lodash";

import { SalesType, DirectUserType, EndUsers } from "content/User/libs";
import {
  CircularProgress,
  ClickAwayListener,
  Tooltip,
} from "@material-ui/core";

// Modal Components
import Zoom from "@material-ui/core/Zoom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { Close, FileCopy } from "@material-ui/icons";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.js";
import ReactJson from "react-json-view";
import modalReceipt from "helpers/modalReceipt";

const styles = (theme) => ({
  ...modalStyle(theme),
  ...style,
});

const CustomTransition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

class RekonLog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPartners: [],
      partners: [],

      selectedCategories: [],
      categories: [],

      selectedProducts: [],
      products: [],

      startDate: moment().startOf("day"),
      endDate: moment().endOf("day"),

      rowsPerPage: 15,
      currentPage: 1,
      totalPages: 0,
      totalData: 0,
      rows: [],

      keyword: "",
      pendingShow: true,
      isLoading: false,

      confirmOpen: false,
      reversalRow: "",

      modalShow: false,
      modalTitle: "",
      modalBody: "",
      receiptBody: null,
    };
  }

  componentDidMount() {
    const user = secureStorage.getItem("user");

    this.loadProduct();
    this.loadCategory();
    user.userTypeId === 1 && this.loadPartner();
  }

  loadPartner() {
    axios
      .post(Config.ApiUrl + "/transaction/partner")
      .then((res) => {
        this.setState({ partners: res.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  loadCategory() {
    axios
      .post(Config.ApiUrl + "/transaction/category")
      .then((res) => {
        this.setState({ categories: res.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  loadProduct() {
    const selectedCategories = this.state.selectedCategories;

    const payload = {
      selectedCategories,
    };

    axios
      .post(Config.ApiUrl + "/transaction/product", payload)
      .then((res) => {
        this.setState({ products: res.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleAdvice = (salesId, productId) => {
    const payload = {
      salesId,
      productId,
    };
    axios
      .post(Config.ApiUrl + "/transaction/advice", payload)
      .then((res) => {
        this.setState({ ["loadingAdv" + salesId]: false });
        this.loadTransaction();
      })
      .catch((error) => {
        // console.log(this.state['loadingAdv'+salesId])
        this.setState({ ["loadingAdv" + salesId]: false });
        console.log(error);
      });
  };

  loadTransaction = (params) => {
    const user = secureStorage.getItem("user");

    this.setState({ isLoading: true });
    const payload = {
      startDate:
        params && params.startDate ? params.startDate : this.state.startDate,
      endDate: params && params.endDate ? params.endDate : this.state.endDate,
      utcOffset: moment().utcOffset(),

      pendingShow: this.state.pendingShow,

      keyword: params && params.keyword ? params.keyword : this.state.keyword,

      products:
        this.state.selectedProducts &&
        this.state.selectedProducts.length > 0 &&
        this.state.selectedProducts,
      categories:
        this.state.selectedCategories &&
        this.state.selectedCategories.length > 0 &&
        this.state.selectedCategories,
      partners:
        this.state.selectedPartners &&
        this.state.selectedPartners.length > 0 &&
        this.state.selectedPartners,
      // pagination payload>>
      currentPage: params && params.currentPage ? params.currentPage : 1,
      rowsPerPage:
        params && params.rowsPerPage
          ? params.rowsPerPage
          : this.state.rowsPerPage,
      // <<pagination payload
    };

    axios
      .post(Config.ApiUrl + "/transaction/pending", payload)
      .then((res) => {
        //console.log(res.data.sales)
        this.setState({
          rows: res.data.sortedSales,
          totalPages: res.data.totalPages,
          totalData: res.data.totalData,
          startDate: payload.startDate,
          endDate: payload.endDate,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };

  getStatus(row) {
    let statusColor = "info";
    switch (row.statusId) {
      case 4:
        statusColor = "success";
        break;
      case 2:
        statusColor = "danger";
        break;
      case 1:
        statusColor = "warning";
        break;
      default:
    }
    return <Badge color={statusColor}>{row.TransactionStatus.name}</Badge>;
  }

  getHeader() {
    const { t } = this.props;
    const user = secureStorage.getItem("user");
    const header = [
      "#",
      "TRID",
      "UID",
      t("name"),
      t("userType"),
      "Item",
      "CustomerCode",
      t("date"),
      "Amount",
      "TrType",
      "Status",
      t("remark"),
      "Detail",
    ];
    if (user.userTypeId === 1) {
      header.push("Advice");
      header.push("Switching");
    }
    return header;
  }

  render() {
    const { classes, t } = this.props;
    const user = secureStorage.getItem("user");

    return (
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.contactContent}>
          <div className={classes.container}>
            <GridContainer justify="center">
              {/* FILTER */}
              <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                {/* TITLE & DATE */}
                <GridContainer justify="center">
                  <GridItem
                    xl={2}
                    lg={2}
                    md={2}
                    sm={12}
                    xs={12}
                    className={classes.grid}
                  >
                    <Label size="huge">REKON LOG</Label>
                  </GridItem>
                  <GridItem xl={10} lg={10} md={10} sm={12} xs={12}>
                    <GSearchActions
                      onChange={({ startDate, endDate }) =>
                        this.setState({ startDate, endDate })
                      }
                      onSubmit={this.loadTransaction}
                    />
                  </GridItem>
                </GridContainer>
                {/* CATEGORY, PRODUCT, PARTNER */}
                <GridContainer justify="center">
                  <GridItem xl={3} lg={3} md={3} sm={12} xs={12}>
                    <Dropdown
                      style={{ marginTop: 25 }}
                      placeholder={t("category")}
                      fluid
                      multiple
                      search
                      selection
                      value={this.state.selectedCategories}
                      onChange={(e, { value }) => {
                        this.setState(
                          {
                            selectedCategories: value,
                            selectedProducts: [],
                          },
                          () => this.loadProduct()
                        );
                      }}
                      options={this.state.categories.map((item, index) => ({
                        key: item.id,
                        text: item.name,
                        value: item.id,
                      }))}
                    />
                  </GridItem>
                  <GridItem xl={3} lg={3} md={3} sm={12} xs={12}>
                    <Dropdown
                      style={{ marginTop: 25 }}
                      placeholder="ITEM"
                      fluid
                      multiple
                      search
                      selection
                      value={this.state.selectedProducts}
                      onChange={(e, { value }) => {
                        this.setState({
                          selectedProducts: value,
                          selectedCategories: [],
                        });
                      }}
                      options={this.state.products.map((item, index) => ({
                        key: item.id,
                        text: item.name,
                        value: item.id,
                      }))}
                    />
                  </GridItem>
                  <GridItem xl={3} lg={3} md={3} sm={12} xs={12}>
                    <Dropdown
                      style={{ marginTop: 25 }}
                      placeholder="Partner"
                      fluid
                      multiple
                      search
                      selection
                      value={this.state.selectedPartners}
                      onChange={(e, { value }) => {
                        this.setState({
                          selectedPartners: value,
                        });
                      }}
                      options={this.state.partners.map((item, index) => ({
                        key: item.id,
                        text: item.name,
                        value: item.id,
                      }))}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>

              {/* RECORD */}
              <GridItem xl={8} lg={8} md={8} sm={8} xs={8}>
                <Statistic size="tiny" style={{ marginTop: 40 }}>
                  <Statistic.Value>
                    {this.state.totalData ? this.state.totalData : 0}
                  </Statistic.Value>
                  <Statistic.Label>Record(s)</Statistic.Label>
                </Statistic>
              </GridItem>

              {/* KEYWORD */}
              <GridItem xl={4} lg={4} md={4} sm={4} xs={4}>
                <Input
                  labelText={t("search")}
                  id="keyword"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    placeholder: "CustomerCode / IDPEL / TRID",
                    value: this.state.keyword,
                    onChange: (e) => {
                      this.setState({ keyword: e.target.value });
                    },
                    onKeyPress: (e) => {
                      if (e.key === "Enter") {
                        this.setState({ keyword: e.target.value });
                        this.loadTransaction({ keyword: e.target.value });
                      }
                    },
                  }}
                />
              </GridItem>

              {/* TABLE LIST */}
              <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                <Segment vertical>
                  <Dimmer active={this.state.isLoading} inverted>
                    <Loader />
                  </Dimmer>

                  <Table
                    stickyHead
                    stickyFoot
                    striped
                    tableHead={this.getHeader()}
                    tableData={this.state.rows.map((row, index) => [
                      index +
                        1 +
                        (this.state.currentPage - 1) *
                          (isNaN(this.state.rowsPerPage)
                            ? 0
                            : this.state.rowsPerPage),
                      row.id.toString().padStart(8, 0),
                      row.User.id.toString().padStart(6, 0),
                      row.User.name,
                      row.User.UserType.name,
                      row.Product.name,
                      row.customerCode,
                      moment(row.updatedAt).format("DD/MM/YYYY HH:mm:ss"),
                      fnumber(row.amount),
                      row.RequestType.name === "inquiry" ? (
                        <Badge color="warning">{row.RequestType.name}</Badge>
                      ) : (
                        <Badge color="info">{row.RequestType.name}</Badge>
                      ),
                      this.getStatus(row),

                      row.receiptBody && row.statusId === 2
                        ? JSON.parse(row.receiptBody)[0]
                        : "-",

                      <Button
                        size="mini"
                        circular
                        color="facebook"
                        onClick={() => {
                          axios
                            .post(Config.ApiUrl + "/transaction/receipt", {
                              id: row.id,
                            })
                            .then((res) => {
                              const { receiptTitle, dataJSON, body } = res.data;
                              const modalTitle = receiptTitle;
                              const modalBody = modalReceipt(body);
                              // console.log({ modalBody });
                              this.setState({
                                modalShow: true,
                                modalTitle,
                                modalBody,
                                receiptBody: dataJSON,
                              });
                              console.log(res.data);
                            })
                            .catch((error) => {
                              console.log(error);
                            });
                        }}
                      >
                        {t("view")}
                      </Button>,

                      user.userTypeId === 1 ? (
                        row.statusId === 1 &&
                        row.RequestType.name === "purchase" ? (
                          this.state["loadingAdv" + row.id] ? (
                            <CircularProgress size={25} />
                          ) : (
                            <span>
                              <Button
                                size="mini"
                                circular
                                color="facebook"
                                onClick={() => {
                                  // console.log('loadingAdv'+row.id)
                                  this.setState({
                                    ["loadingAdv" + row.id]: true,
                                  });
                                  // this.handleAdvice(row.id,row.productId)
                                  const salesId = row.id;
                                  const productId = row.productId;
                                  const payload = {
                                    salesId,
                                    productId,
                                  };
                                  axios
                                    .post(
                                      Config.ApiUrl + "/transaction/advice",
                                      payload
                                    )
                                    .then((res) => {
                                      this.setState({
                                        ["loadingAdv" + salesId]: false,
                                      });
                                      this.loadTransaction();
                                    })
                                    .catch((error) => {
                                      // console.log(this.state['loadingAdv'+salesId])
                                      this.setState({
                                        ["loadingAdv" + salesId]: false,
                                      });
                                      console.log(error);
                                    });
                                }}
                              >
                                ADVICE
                              </Button>
                              <Button
                                size="mini"
                                circular
                                color="youtube"
                                onClick={() => {
                                  this.setState({
                                    confirmOpen: true,
                                    reversalRow: row,
                                  });
                                  // this.handleAdvice(row.id,row.productId)
                                }}
                              >
                                REVERSAL
                              </Button>
                            </span>
                          )
                        ) : (
                          "-"
                        )
                      ) : null,
                      user.userTypeId === 1 ? row.Partner.name : null,
                    ])}
                    textAlignForHeaders={[8]}
                    textAlignHeaders={["right"]}
                    textAlignForCells={[8]}
                    textAlignCells={["right"]}
                  />
                </Segment>
                <Confirm
                  open={this.state.confirmOpen}
                  onCancel={() => this.setState({ confirmOpen: false })}
                  content={
                    this.state.reversalRow &&
                    `Are you sure doing reversal TRID : ${this.state.reversalRow.id
                      .toString()
                      .padStart(8, 0)} ?`
                  }
                  onConfirm={() => {
                    const salesId = this.state.reversalRow.id;
                    const productId = this.state.reversalRow.productId;
                    axios
                      .post(Config.ApiUrl + "/transaction/reversal", {
                        salesId,
                        productId,
                      })
                      .then((res) => {
                        // console.log(this.state['loadingAdv'+salesId])
                        // _.find(this.state.extractedRows,{id:salesId})
                        this.setState({
                          ["loadingAdv" + salesId]: false,
                          confirmOpen: false,
                        });
                        this.loadTransaction();
                      })
                      .catch((error) => {
                        // console.log(this.state['loadingAdv'+salesId])
                        this.setState({
                          ["loadingAdv" + salesId]: false,
                          confirmOpen: false,
                        });
                        this.loadTransaction();
                        console.log(error);
                      });
                  }}
                />
              </GridItem>

              {/* PAGING */}
              <GridItem
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className={classes.grid}
              >
                <div className={classes.justifyContentCenter}>
                  <Pagination
                    totalButton={7}
                    totalPages={this.state.totalPages}
                    totalData={this.state.totalData}
                    currentPage={this.state.currentPage}
                    onChangePage={(currentPage) => {
                      if (currentPage !== this.state.currentPage) {
                        this.setState({ currentPage });
                        this.loadTransaction({ currentPage });
                      }
                    }}
                    rowsPerPage={[5, 15, 25, "All"]}
                    defaultRowsPerPage={15}
                    onChangeRowsPerPage={(value) => {
                      this.setState({ rowsPerPage: value, currentPage: 1 });
                      this.loadTransaction({
                        rowsPerPage: value,
                        currentPage: 1,
                      });
                    }}
                  />
                </div>
              </GridItem>
            </GridContainer>

            {/* MODAL */}
            <Dialog
              classes={{
                root: classes.modalRoot,
                paper: classes.modal,
              }}
              open={this.state.modalShow}
              TransitionComponent={CustomTransition}
              keepMounted
              //onClose={() => this.setState({successModal: false})}
              aria-labelledby="classic-modal-slide-title"
              aria-describedby="classic-modal-slide-description"
            >
              <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <h4 className={classes.modalTitle}>{this.state.modalTitle}</h4>
              </DialogTitle>
              <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}
              >
                {/* {this.state.modalBody} */}
                <Tab
                  panes={[
                    {
                      menuItem: "Struk",
                      render: () => <Tab.Pane>{this.state.modalBody}</Tab.Pane>,
                    },
                    {
                      menuItem: "JSON",
                      render: () => (
                        <Tab.Pane>
                          <div style={{ textAlign: "right" }}>
                            <ClickAwayListener
                              onClickAway={(e) =>
                                this.setState({ tooltipOpen: false })
                              }
                            >
                              <Tooltip
                                PopperProps={{
                                  disablePortal: true,
                                }}
                                onClose={(e) =>
                                  this.setState({ tooltipOpen: false })
                                }
                                open={this.state.tooltipOpen}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                placement="top-end"
                                title="Copied"
                              >
                                <Button
                                  size="mini"
                                  color="twitter"
                                  onClick={() => {
                                    this.setState({ tooltipOpen: true });
                                    console.log(this.state.receiptBody);
                                    navigator.clipboard.writeText(
                                      JSON.stringify(
                                        this.state.receiptBody,
                                        null,
                                        2
                                      )
                                    );
                                  }}
                                >
                                  <FileCopy />
                                </Button>
                              </Tooltip>
                            </ClickAwayListener>
                          </div>
                          <ReactJson
                            displayObjectSize={false}
                            src={this.state.receiptBody}
                            name={false}
                            enableClipboard={false}
                            displayDataTypes={false}
                          />
                        </Tab.Pane>
                      ),
                    },
                  ]}
                />
              </DialogContent>
              <DialogActions className={classes.modalFooter}>
                <CButton
                  onClick={() => this.setState({ modalShow: false })}
                  color="facebook"
                  round
                >
                  <Close />
                  {t("close")}
                </CButton>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
}

RekonLog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withTranslation(), withStyles(styles))(RekonLog);
