// cores
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";

import { withTranslation } from "react-i18next";
import { compose } from "redux";

// UI components
import Button from "components/CustomButtons/Button";
import Table from "components/Table/Table";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Pagination from "components/Pagination/Pagination.js";
import Badge from "components/Badge/Badge.js";

// Icons
import SearchIcon from "@material-ui/icons/Search";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Check from "@material-ui/icons/Check";

import { Dropdown, Dimmer, Loader, Segment, Label } from "semantic-ui-react";

// libs
import Config from "config";
import axios from "axios";
import { fnumber, formatDateTime } from "components/Helper";
import GSearchActions from "components/GSearchActions.js";
import moment from "moment";
import secureStorage from "libs/secureStorage";

// styles
import style from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";

const styles = (theme) => ({
  ...style,
});

class BillingUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // paging states >>
      rowsPerPage: 15,
      currentPage: 1,
      totalPages: 0,
      totalData: 0,
      rows: [],
      //<<pagin states
      startDate: moment().startOf("day"),
      endDate: moment().endOf("day"),

      selectedProducts: [],
      products: [],

      selectedCategories: [],
      categories: [],

      isLoading: false,
    };
  }

  componentDidMount() {
    this.loadProduct();

    this.loadBilling();
  }

  loadBilling = (params) => {
    const userId = this.props.match.params.userId;
    // console.log(this.props.match.params)
    this.setState({ isLoading: true });
    const payload = {
      userId,
      startDate:
        params && params.startDate ? params.startDate : this.state.startDate,
      endDate: params && params.endDate ? params.endDate : this.state.endDate,
      // paging payload >>
      currentPage: params && params.currentPage ? params.currentPage : 1,
      rowsPerPage:
        params && params.rowsPerPage
          ? params.rowsPerPage
          : this.state.rowsPerPage,
      // << paging payload
      products:
        this.state.selectedProducts &&
        this.state.selectedProducts.length > 0 &&
        this.state.selectedProducts,
      // selectedUser : this.props.location.selectedUser
    };

    axios
      .post(Config.ApiUrl + "/user/billing", payload)
      .then((res) => {
        // console.log(res.data)
        this.setState({
          name: res.data.name,
          rows: res.data.billings,
          totalPages: res.data.totalPages,
          totalData: res.data.totalData,
          startDate: payload.startDate,
          endDate: payload.endDate,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };

  loadProduct() {
    axios
      .post(Config.ApiUrl + "/product/dropdown")
      .then((res) => {
        this.setState({ products: res.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    // const userId = this.props.match.params.userId && parseInt(this.props.match.params.userId)
    const { classes, t } = this.props;
    const user = secureStorage.getItem("user");
    const { selectedUser } = this.props.location;
    // if (!selectedUser)
    // 	return <Redirect to='/User' />
    return (
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.contactContent}>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                <GridContainer justify="center">
                  <GridItem
                    xl={3}
                    lg={3}
                    md={3}
                    sm={12}
                    xs={12}
                    className={classes.grid}
                  >
                    <Button
                      onClick={(e) => {
                        if (
                          this.props.location &&
                          this.props.location.state &&
                          this.props.location.state.backPayload
                        ) {
                          secureStorage.setItem(
                            "backPayload",
                            this.props.location.state.backPayload
                          );
                        }
                        this.props.history.goBack();
                      }}
                      color="danger"
                      round
                    >
                      <ArrowBackIosIcon />
                      {t("back")}
                    </Button>
                    <br />
                    <Label size="big">{t("BILLING")}</Label>
                    {this.state.name && (
                      <Label size="medium" color="orange">
                        {this.state.name}
                      </Label>
                    )}
                  </GridItem>
                  <GridItem xl={9} lg={9} md={9} sm={12} xs={12}>
                    <GridContainer justify="center">
                      <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                        <GSearchActions
                          onChange={({ startDate, endDate }) =>
                            this.setState({ startDate, endDate })
                          }
                          onSubmit={this.loadBilling}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer justify="center">
                      <GridItem xl={3} lg={3} md={3} sm={12} xs={12}>
                        <Dropdown
                          style={{ marginTop: 25 }}
                          placeholder="ITEM"
                          fluid
                          multiple
                          search
                          selection
                          value={this.state.selectedProducts}
                          onChange={(e, { value }) => {
                            this.setState({
                              selectedProducts: value,
                              selectedCategories: [],
                            });
                          }}
                          options={this.state.products.map((item, index) => ({
                            key: item.id,
                            text: item.name,
                            value: item.id,
                          }))}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                <Segment vertical>
                  <Dimmer active={this.state.isLoading} inverted>
                    <Loader />
                  </Dimmer>
                  <Table
                    sortable
                    stickyHead
                    stickyFoot
                    striped
                    tableHead={[
                      "#",
                      t("Product"),
                      t("Date"),
                      t("Remark"),
                      t("Amount"),
                      "Total Nominal",
                    ]}
                    tableData={this.state.rows.map((row, index) => [
                      index +
                        1 +
                        (isNaN(this.state.rowsPerPage)
                          ? 0
                          : (this.state.currentPage - 1) *
                            this.state.rowsPerPage),
                      row.TrSale && row.TrSale.Product && row.TrSale.Product
                        ? row.TrSale.Product.name
                        : "-",
                      formatDateTime(row.createdAt),
                      row.remark ? row.remark : "-",
                      fnumber(row.amount),
                      fnumber(row.currAmount),
                    ])}
                    textAlignCells={[
                      "center",
                      "center",
                      "center",
                      "right",
                      "right",
                    ]}
                    textAlignForCells={[2, 3, 4, 5, 6]}
                    textAlignHeaders={[
                      "center",
                      "center",
                      "center",
                      "right",
                      "right",
                    ]}
                    textAlignForHeaders={[2, 3, 4, 5, 6]}
                    rawData={this.state.rows}
                    onSort={(data) => this.setState({ rows: data })}
                  />
                </Segment>
              </GridItem>
              <GridItem
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className={classes.grid}
              >
                <div className={classes.justifyContentCenter}>
                  <Pagination
                    totalButton={7}
                    totalPages={this.state.totalPages}
                    totalData={this.state.totalData}
                    currentPage={this.state.currentPage}
                    onChangePage={(currentPage) => {
                      if (currentPage !== this.state.currentPage) {
                        this.setState({ currentPage });
                        this.loadBilling({ currentPage });
                      }
                    }}
                    // pages={[
                    // 	...this.paginationButton(),
                    // ]}
                    rowsPerPage={[5, 15, 25, "All"]}
                    defaultRowsPerPage={this.state.rowsPerPage}
                    onChangeRowsPerPage={(value) => {
                      console.log(value);
                      this.setState({ rowsPerPage: value, currentPage: 1 });
                      this.loadBilling({ rowsPerPage: value, currentPage: 1 });
                    }}
                  />
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }
}

BillingUser.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withTranslation(), withStyles(styles))(BillingUser);
