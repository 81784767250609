// Core
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import classNames from "classnames";

import { withTranslation } from "react-i18next";
import { compose } from "redux";

//Components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Badge from "components/Badge/Badge.js";
import Tooltip from "@material-ui/core/Tooltip";
// import Table from "components/Table/Table";
import Input from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  Label,
  Dimmer,
  Loader,
  Segment,
  Button as ButtonSemantic,
} from "semantic-ui-react";

// Icons

// Styles
import style from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";
import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.js";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.js";

// Libs
import axios from "axios";
import Config from "config";
import { fnumber } from "components/Helper";
import moment from "moment";
import "moment/locale/id";
import secureStorage from "libs/secureStorage";

// Modal Components
import Zoom from "@material-ui/core/Zoom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import Pagination from "components/Pagination/Pagination.js";

import { Table } from "semantic-ui-react";

const styles = (theme) => ({
  ...modalStyle(theme),
  ...style,
  ...tooltipsStyle,
  buttons: {
    marginTop: "25px",
  },
  oddRow: {
    backgroundColor: "#e8f4f7",
  },
  evenRow: {
    backgroundColor: "#cbe3ee",
  },
  tableResponsive: {
    minHeight: "0.1%",
    overflowX: "auto",
    // overflowY: 'scroll'
  },
  table: {
    marginBottom: "0",
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "transparent",
    borderSpacing: "0",
    borderCollapse: "collapse",
    overflow: "auto",
    "& > tbody > tr, & > thead > tr, & > tfoot > tr,": {
      height: "auto",
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

const userTypeIdBill = [1, 2, 19, 20, 24, 26, 27];
class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: secureStorage.getItem("user"),

      // paging states >>
      rowsPerPage: 5,
      currentPage: 1,
      totalPages: 0,
      totalData: 0,
      rows: [],
      //<<pagin states

      keyword: "",

      modalMode: "", // action after confirmation

      // modal form bill
      modalShow: false,
      modalTitle: "",

      modalName: "",
      modalAmount: "",
      modalRemark: "",

      // Modal Confirmation
      modalConfirmShow: false,
      modalConfirmTitle: "",
      modalConfirmMsg: "",

      // Modal Form SplitBill
      modalSplitShow: false,
      modalSplitTitle: "",
      modalSplitAmount: 0,
      modalRemainingAmount: 0,

      selectedBill: "",
      selectedParentBill: "",

      banks: [],
      selectedBank: "",

      isLoading: true,
      deleteButtonDisabled: false,
      splitButtonDisabled: true,

      info: "",
    };
  }
  componentDidMount() {
    moment.locale("id");
    this.loadBill();
    this.initBank();

    const info = secureStorage.getItem("domainInfo");
    this.setState({ info });
  }
  initBank() {
    axios
      .post(Config.ApiUrl + "/payment/bank")
      .then((res) => {
        // console.log(res.data)
        this.setState({ banks: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  loadBill = (params) => {
    axios
      .post(Config.ApiUrl + "/payment", {
        currentPage: params && params.currentPage ? params.currentPage : 1,
        rowsPerPage:
          params && params.rowsPerPage
            ? params.rowsPerPage
            : this.state.rowsPerPage,
      })
      .then((res) => {
        //console.log(params &&  params.partnerDropdown?'true':'false')
        console.log(res.data);
        this.setState({
          rows: res.data.bills,
          totalPages: res.data.totalPages,
          totalData: res.data.totalData,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleDropDownChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleClear = (e) => {
    this.setState({
      keyword: "",
    });
    this.loadBill({ keyword: "" });
  };

  handleShowModal = () => {
    this.setState({ modalShow: true });
  };

  handleModal = (target) => {
    // console.log({ target, bill: this.state.selectedBill, user: this.state.modalName })
    const { t } = this.props;
    const bill = this.state.selectedBill;
    if (target === "confirm") {
      this.setState({ modalMode: target });
      this.handleConfirm();
    } else if (target === "delete") {
      this.setState({
        // modalShow: false,
        modalMode: "delete",
        modalConfirmShow: true,
        modalConfirmTitle: t("Confirm"),
        modalConfirmMsg: `${t("deleteBill")} ${this.state.modalName} ${fnumber(
          parseInt(bill.uniqueAmount)
        )} ?`,
      });
    } else if (target === "split") {
      this.setState({ modalMode: target });
      this.handleSplit();
    } else
      this.setState({
        modalShow: false,
        modalSplitShow: false,
        modalConfirmShow: false,
        selectedBank: "",
      });
  };

  handleConfirm = () => {
    if (this.state.modalAmount && this.state.selectedBill) {
      this.setState({
        isLoading: true,
        modalShow: false,
        modalConfirmShow: false,
        modalConfirmMsg: "",
        modalConfirmTitle: "",
        modalRemark: "",
      });

      const payload = {
        remark: this.state.modalRemark,
        amount: this.state.modalAmount,
        selectedBill: this.state.selectedBill,
        selectedParentBill: this.state.selectedParentBill,
        selectedBank: this.state.selectedBank,
      };
      axios
        .post(Config.ApiUrl + "/payment/confirm", payload)
        .then((res) => {
          this.setState({ selectedBill: "", isLoading: true }, () => {
            this.loadBill();
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ isLoading: false });
        });
    }
  };

  handleDel = () => {
    this.setState({
      modalShow: false,
      modalConfirmShow: false,
      modalConfirmMsg: "",
      modalConfirmTitle: "",
      isLoading: true,
    });

    // eslint - disable - next - line no - unused - vars
    const payload = {
      selectedBank: this.state.selectedBank,
      selectedBill: this.state.selectedBill,
      selectedParentBill: this.state.selectedParentBill,
    };

    axios
      .post(`${Config.ApiUrl}/payment/delete`, payload)
      .then((res) => {
        this.setState({ isLoading: false, selectedBill: "" }, () =>
          this.loadBill()
        );
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
      });
  };

  handleSplit = () => {
    // console.log('[PROSES SPLIT]')
    if (this.state.modalSplitAmount) {
      this.setState({
        isLoading: true,
        modalConfirmShow: false,
        modalSplitShow: false,
        modalConfirmMsg: "",
        modalConfirmTitle: "",
      });

      const payload = {
        splitAmount: this.state.modalSplitAmount,
        bill: this.state.selectedBill,
      };

      axios
        .post(Config.ApiUrl + "/bill/split", payload)
        .then((res) => {
          // console.log({ res })
          this.setState(
            {
              isLoading: false,
              splitButtonDisabled: true,
              modalSplitAmount: 0,
              modalRemainingAmount: 0,
            },
            () => this.loadBill()
          );
        })
        .catch((err) => {
          console.log(err);
          this.setState({ isLoading: false });
        });
    }
  };

  handleSubmit = () => {
    if (this.state.modalMode === "confirm") this.handleConfirm();
    else if (this.state.modalMode === "split") this.handleSplit();
    else this.handleDel();
  };

  renderStatus(bill) {
    let color;
    switch (bill.statusId) {
      case 2:
        color = "twitter";
        break;
      case 3:
        color = "warning";
        break;
      case 4:
        color = "success";
        break;
      case 6:
        color = "danger";
        break;
      default:
        color = "facebook";
    }

    return <Badge color={color}>{bill.BillStatus.name}</Badge>;
  }
  renderButton(bill, parentBill) {
    const user = this.state.user;
    // eslint-disable-next-line react/prop-types
    const { t, classes } = this.props;
    let disabled = false;
    if (bill.Splitted && bill.Splitted.length > 0) disabled = true;
    if ([4, 5].includes(bill.statusId)) disabled = true;
    if (!userTypeIdBill.includes(user.userTypeId)) disabled = true;
    // 	disabled = true
    // if(bill.statusId === 6 && !bill.bankId)
    // 	disabled = true

    return (
      <Tooltip
        id="tooltip-search"
        title={t("Confirm")}
        placement="top"
        classes={{ tooltip: classes.tooltip }}
      >
        <Button
          color="instagram"
          size="sm"
          round
          disabled={disabled}
          onClick={() => {
            this.setState({
              modalMode: "confirm",
              modalShow: true,
              modalTitle: `${t("payment")} ${t("Confirm")}`,
              modalName: !parentBill
                ? bill.BillCharged.name
                : parentBill.BillCharged.name,
              // modalAmount: bill.statusId === 2 ? bill.uniqueAmount : '0',
              modalRemark: bill.remark ?? "",
              modalAmount: bill.uniqueAmount ?? "0",
              selectedBill: bill,
              selectedParentBill: parentBill,
              selectedBank: "",
              deleteButtonDisabled: bill.parentId ? false : true,
            });
          }}
        >
          {t("Confirm")}
        </Button>
      </Tooltip>
    );
  }

  renderRow = ({ row, index, parent, isChild, depth }) => {
    const info = this.state.info;
    const user = this.state.user;
    const { classes, t } = this.props;
    const splitted = row.Splitted;

    const amount = row.amount ? row.amount : 0;
    const paidAmount = row.paidAmount ? row.paidAmount : 0;
    const remaining = amount - paidAmount;
    const rows = [
      <Table.Row
        key={index}
        className={index % 2 === 0 ? classes.evenRow : classes.oddRow}
      >
        <Table.Cell>
          <span style={{ marginLeft: isChild ? depth + 10 : 0 }}>
            {splitted && index + 1}
          </span>
          {(splitted && splitted.length > 0) ||
          (row.Children && row.Children.length > 0) ? (
            !this.state["expanded" + row.id] ? (
              <ButtonSemantic
                size="mini"
                circular
                icon="chevron circle right"
                style={{ marginLeft: 5 }}
                onClick={() => {
                  this.setState({ ["expanded" + row.id]: true });
                }}
              />
            ) : (
              <ButtonSemantic
                size="mini"
                circular
                icon="chevron circle down"
                style={{ marginLeft: 5 }}
                onClick={() => {
                  this.setState({ ["expanded" + row.id]: false });
                }}
              />
            )
          ) : null}
        </Table.Cell>
        <Table.Cell>{row.id.toString().padStart(6, 0)}</Table.Cell>
        <Table.Cell>
          {splitted
            ? `${row.BillCharged.id.toString().padStart(6, 0)} (${
                row.BillCharged.UserType?.name
              })`
            : ""}
        </Table.Cell>
        <Table.Cell>{splitted && row.BillCharged.name}</Table.Cell>
        <Table.Cell>
          {splitted &&
            (row.cycle === 1
              ? moment(row.createdAt)
                  .startOf("day")
                  .add(11, "hours")
                  .format("LLLL")
              : moment(row.createdAt).add(3, "hours").format("LLLL"))}
        </Table.Cell>
        <Table.Cell>{row.Bank && row.Bank.name}</Table.Cell>
        <Table.Cell>{row.uniqueAmount && fnumber(row.uniqueAmount)}</Table.Cell>
        <Table.Cell>{fnumber(amount)}</Table.Cell>
        <Table.Cell>{fnumber(paidAmount)}</Table.Cell>
        <Table.Cell>{splitted ? fnumber(remaining) : ""}</Table.Cell>
        <Table.Cell>{this.renderStatus(row)}</Table.Cell>
        <Table.Cell>
          {splitted ? this.renderButton(row) : this.renderButton(row, parent)}
        </Table.Cell>
      </Table.Row>,
    ];

    // splitted bill isExpanded
    const billSplitted =
      splitted && this.state["expanded" + row.id]
        ? splitted.map((item, idx) => {
            return this.renderRow({ row: item, index: idx, parent: row });
          })
        : [];

    const buttonSplit = [
      <Table.Row key={`foot-${index}`}>
        <Table.Cell colSpan="11"></Table.Cell>
        <Table.Cell>
          <Tooltip
            id="tooltip-search"
            title={t("splitBill")}
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              round
              size="sm"
              disabled={userTypeIdBill.includes(user.userTypeId) ? false : true}
              color="instagram"
              onClick={() => {
                if (row) {
                  let remainingAmount = row.amount;
                  row.Splitted.map((split) => {
                    remainingAmount -= split.amount;
                    return split;
                  });
                  if (remainingAmount === 0)
                    this.setState({ splitButtonDisabled: true });

                  this.setState({
                    modalSplitShow: true,
                    modalSplitTitle: `${t("splitBill").toUpperCase()} ${
                      row.BillCharged.name
                    } - ${row.BillCharged.id.toString().padStart(6, 0)}`,
                    modalSplitAmount: 0,
                    selectedBill: row,
                    modalRemainingAmount: remainingAmount,
                  });
                }
              }}
            >
              {t("splitBill")}
            </Button>
          </Tooltip>
        </Table.Cell>
      </Table.Row>,
    ];

    const rowFoot =
      info && ["saminara.com"].includes(info.name) && splitted
        ? buttonSplit
        : info &&
          !["saminara.com"].includes(info.name) &&
          splitted &&
          splitted.length > 0 &&
          this.state["expanded" + row.id]
        ? buttonSplit
        : [];

    // splitted && splitted.length > 0 && this.state["expanded" + row.id]
    //   ? buttonSplit
    //   : [];

    // EXPANDED BILL CHILDREN
    let wrapperChild = [];
    if (
      row.Children &&
      row.Children.length > 0 &&
      this.state["expanded" + row.id]
    ) {
      wrapperChild.push(
        <Table.Row
          key={`child-${row.id}`}
          className={index % 2 === 0 ? classes.evenRow : classes.oddRow}
        >
          <Table.Cell colSpan="1">
            {!this.state[`expanded-child${row.id}`] ? (
              <ButtonSemantic
                size="mini"
                circular
                icon="chevron circle right"
                style={{ marginLeft: depth + 30 }}
                onClick={() => {
                  this.setState({ [`expanded-child${row.id}`]: true });
                }}
              />
            ) : (
              <ButtonSemantic
                size="mini"
                circular
                icon="chevron circle down"
                style={{ marginLeft: depth + 30 }}
                onClick={() => {
                  this.setState({ [`expanded-child${row.id}`]: false });
                }}
              />
            )}
          </Table.Cell>
          <Table.Cell colSpan="11">
            <b>
              {!this.state[`expanded-child${row.id}`]
                ? "TAMPILKAN"
                : "SEMBUNYIKAN"}{" "}
              TAGIHAN LOKET ATAU SEJENISNYA
            </b>
          </Table.Cell>
        </Table.Row>
      );
      const childBill = this.state[`expanded-child${row.id}`]
        ? row.Children.map((item, idx) => {
            return this.renderRow({
              row: item,
              index: idx,
              parent: row,
              isChild: true,
              depth: depth + 30,
            });
          })
        : [];

      wrapperChild = wrapperChild.concat(childBill);
    }
    // END

    return rows.concat(billSplitted, rowFoot, wrapperChild);
  };

  render() {
    const { classes, t } = this.props;
    let allRows = [];
    this.state.rows.map((row, index) => {
      allRows = allRows.concat(this.renderRow({ row, index, depth: 0 }));
      return row;
    });

    return (
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.contactContent}>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                <GridContainer justify="center">
                  <GridItem
                    xl={2}
                    lg={2}
                    md={2}
                    sm={12}
                    xs={12}
                    className={classes.grid}
                  >
                    <Label size="big">Payments</Label>
                  </GridItem>
                  <GridItem xl={10} lg={10} md={10} sm={12} xs={12}>
                    <GridContainer justify="center">
                      <GridItem xl={2} lg={2} md={3} sm={6} xs={6}></GridItem>
                      <GridItem xl={2} lg={2} md={3} sm={6} xs={6}></GridItem>
                      <GridItem xl={2} lg={2} md={3} sm={6} xs={6}></GridItem>
                      <GridItem xl={2} lg={2} md={3} sm={6} xs={6}></GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className={classes.grid}
              >
                <div className={classes.tableResponsive}>
                  <Table selectable unstackable className={classes.table}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>#</Table.HeaderCell>
                        <Table.HeaderCell>BID</Table.HeaderCell>
                        <Table.HeaderCell>UID</Table.HeaderCell>
                        <Table.HeaderCell>{t("Name")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("dueDate")}</Table.HeaderCell>
                        <Table.HeaderCell>Bank</Table.HeaderCell>
                        <Table.HeaderCell>{t("UnqAmount")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("dueAmount")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("paidAmount")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("Remaining")}</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell>{t("action")}</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {allRows}
                      {/* {
												this.state.rows.map((bill, index) => {
													const loop = bill.Splitted.length === 0 ? 1 : bill.Splitted.length + 1
													const tableRow = []
													for (let i = 0; i < loop; i++) {
														const splitted = i > 0 && bill.Splitted.length > 0 && bill.Splitted[i - 1]
														tableRow.push(<Table.Row key={index + i} className={index % 2 === 0 ? classes.evenRow : classes.oddRow}>
															<Table.Cell>{i === 0 && (index + 1) + (isNaN(this.state.rowsPerPage) ? 0 : ((this.state.currentPage - 1) * this.state.rowsPerPage))}</Table.Cell>
															<Table.Cell>{i === 0 ? bill.id.toString().padStart(6, 0) : splitted.id.toString().padStart(6, 0)}</Table.Cell>
															<Table.Cell>{i === 0 && bill.BillCharged.id.toString().padStart(6, 0)}</Table.Cell>
															<Table.Cell>{i === 0 && bill.BillCharged.name}</Table.Cell>
															<Table.Cell>{i === 0 && (bill.cycle === 1 ?
																moment(bill.createdAt).startOf('day').add(11, 'hours').format('LLLL')
																:
																moment(bill.createdAt).add(3, 'hours').format('LLLL'))
															}</Table.Cell>
															<Table.Cell>{i === 0 ? bill.Bank && bill.Bank.name : splitted.Bank && splitted.Bank.name}</Table.Cell>
															<Table.Cell>{i === 0 ? bill.Splitted.length === 0 && fnumber(bill.uniqueAmount) : fnumber(splitted.uniqueAmount)}</Table.Cell>
															<Table.Cell>{i === 0 ? fnumber(bill.amount) : fnumber(splitted.amount)}</Table.Cell>
															<Table.Cell>{i === 0 ? fnumber(bill.paidAmount ? bill.paidAmount : 0) : fnumber(splitted.paidAmount ? splitted.paidAmount : 0)}</Table.Cell>
															<Table.Cell>{i === 0 ? this.renderStatus(bill) : this.renderStatus(splitted)}</Table.Cell>
															<Table.Cell>{i === 0 ? this.renderButton(bill) : this.renderButton(splitted, bill)}</Table.Cell>
														</Table.Row>)
													}
													return tableRow
												})
											} */}
                    </Table.Body>
                  </Table>
                </div>
              </GridItem>
              <GridItem
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className={classes.grid}
              >
                <div className={classes.justifyContentCenter}>
                  <Pagination
                    totalButton={7}
                    totalPages={this.state.totalPages}
                    totalData={this.state.totalData}
                    currentPage={this.state.currentPage}
                    onChangePage={(currentPage) => {
                      if (currentPage !== this.state.currentPage) {
                        this.setState({ currentPage });
                        this.loadBill({ currentPage });
                      }
                    }}
                    // pages={[
                    // 	...this.paginationButton(),
                    // ]}
                    rowsPerPage={[5, 15, 25, "All"]}
                    defaultRowsPerPage={this.state.rowsPerPage}
                    onChangeRowsPerPage={(value) => {
                      console.log(value);
                      this.setState({ rowsPerPage: value, currentPage: 1 });
                      this.loadBill({ rowsPerPage: value, currentPage: 1 });
                    }}
                  />
                </div>
              </GridItem>
            </GridContainer>

            {/* Dialog Form */}
            <Dialog
              classes={{
                root: classes.modalRoot,
                paper: classes.modal,
              }}
              open={this.state.modalShow}
              TransitionComponent={Transition}
              keepMounted
              //onClose={() => this.setState({successModal: false})}
              aria-labelledby="classic-modal-slide-title"
              aria-describedby="classic-modal-slide-description"
            >
              <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <h4 className={classes.modalTitle}>{this.state.modalTitle}</h4>
              </DialogTitle>
              <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}
              >
                {/* {this.state.modalMode === 'pending' ?
									<GridContainer justify='center'>
										<GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
											Are you sure want to {this.state.modalTitle} ?
										</GridItem>
									</GridContainer>
									: */}
                <GridContainer justify="center">
                  <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Input
                      labelText={t("Name")}
                      id="name"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: t("Name"),
                        value: this.state.modalName,
                        readOnly: true,
                      }}
                    />
                    <Input
                      labelText={t("dueAmount")}
                      id="dueAmount"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        readOnly: true,
                        placeholder: t("dueAmount"),
                        value: fnumber(this.state.selectedBill.amount),
                        // onChange : (e)=> this.setState({modalAmount:e.target.value.replace(/\./g, '')}),
                      }}
                    />
                    <Input
                      labelText="Transfer Nominal"
                      id="amount"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: "Amount",
                        value: fnumber(this.state.modalAmount),
                        onChange: (e) => {
                          let val = e.target.value.replace(/\./g, "");
                          val = !val || isNaN(val) ? "0" : val;

                          this.setState({ modalAmount: val });
                        },
                      }}
                    />
                    {this.state.selectedBill.Bank ? (
                      <Input
                        labelText="Bank"
                        id="bank"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          readOnly: true,
                          placeholder: "Bank",
                          value: this.state.selectedBill.Bank.name,
                        }}
                      />
                    ) : (
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor={"bank"}
                          className={classes.selectLabel}
                        >
                          Bank
                        </InputLabel>
                        <Select
                          MenuProps={{ className: classes.selectMenu }}
                          classes={{ select: classes.select }}
                          inputProps={{ name: "bank", id: "bank" }}
                          value={this.state.selectedBank}
                          onChange={(e) =>
                            this.setState({ selectedBank: e.target.value })
                          }
                        >
                          <MenuItem
                            disabled
                            classes={{ root: classes.selectMenuItem }}
                            value={-1}
                          >
                            {this.state.banks.length > 0 ? (
                              "Bank"
                            ) : (
                              <CircularProgress size={30} />
                            )}
                          </MenuItem>
                          {this.state.banks.map((item, index) => (
                            <MenuItem
                              key={index}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value={item}
                            >
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    <Input
                      labelText={t("remark")}
                      id="modalRemark"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: t("remark"),
                        value: this.state.modalRemark,
                        onChange: (e) => {
                          this.setState({ modalRemark: e.target.value });
                        },
                        // onChange : (e)=> this.setState({modalAmount:e.target.value.replace(/\./g, '')}),
                      }}
                    />
                  </GridItem>
                </GridContainer>
                {/* } */}
              </DialogContent>
              {/* Modal Action */}
              <DialogActions className={classes.modalFooter}>
                <Button
                  onClick={() => this.handleModal("back")}
                  round
                  color="warning"
                >
                  Back
                </Button>
                {/* {this.state.modalMode === 'confirm' ?
									(
										<> */}
                {this.state.selectedBill && this.state.selectedBill.parentId && (
                  <Button
                    round
                    color="danger"
                    disabled={this.state.deleteButtonDisabled}
                    onClick={() => this.handleModal("delete")}
                  >
                    Delete
                  </Button>
                )}
                <Button
                  round
                  color="success"
                  onClick={() => this.handleModal("confirm")}
                  disabled={
                    this.state.selectedBill &&
                    // (this.state.selectedBill.Bank || this.state.selectedBank) &&
                    parseInt(this.state.modalAmount.replace(/\./g, "")) > 0
                      ? false
                      : true
                  }
                >
                  {t("Confirm")}
                </Button>
                {/* </>
									)
									: null
								} */}
                {/* {this.state.modalMode === 'cancel' ?
									<Button onClick={() => this.handleModal('cancel')} round color="danger">Cancel</Button> : null}
								{this.state.modalMode === 'pending' ?
									<Button onClick={() => this.handleModal('pending')} round color="danger">Delete</Button> : null} */}
              </DialogActions>
            </Dialog>

            {/* Dialog Form SplitBill */}
            <Dialog
              classes={{
                root: classes.modalRoot,
                paper: classes.modal,
              }}
              open={this.state.modalSplitShow}
              TransitionComponent={Transition}
              keepMounted
              //onClose={() => this.setState({successModal: false})}
              aria-labelledby="classic-modal-slide-title"
              aria-describedby="classic-modal-slide-description"
            >
              {/* Modal Title */}
              <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <h4 className={classes.modalTitle}>
                  {this.state.modalSplitTitle}
                </h4>
              </DialogTitle>
              {/* Modal Body */}
              <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}
              >
                <GridContainer justify="center">
                  <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                    {/* Amount Split */}
                    <Input
                      labelText={t("splitAmount")}
                      id="splitAmount"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: t("splitAmount"),
                        value: fnumber(this.state.modalSplitAmount),
                        onChange: (e) => {
                          let val = e.target.value.replace(/\./g, "");
                          // const dueAmount = this.state.bill && this.state.bill.amount
                          val = !val || isNaN(val) ? 0 : parseInt(val);
                          if (val > this.state.modalRemainingAmount) {
                            val = 0;
                          }

                          let splitButtonDisabled = true;
                          if (val !== 0) {
                            splitButtonDisabled = false;
                          }
                          this.setState({
                            splitButtonDisabled,
                            modalSplitAmount: val,
                          });
                        },
                      }}
                    />
                    {/* Amount Remaining */}
                    <Input
                      key="remainingAmount"
                      labelText={t("remainingAmount")}
                      id="remainingAmount"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        readOnly: true,
                        value: fnumber(this.state.modalRemainingAmount),
                        placeholder: "Remaining Amount",
                        type: "tel",
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </DialogContent>
              {/* Modal Action */}
              <DialogActions className={classes.modalFooter}>
                <Button
                  onClick={() => this.handleModal("back")}
                  round
                  color="warning"
                >
                  Back
                </Button>
                <Button
                  round
                  color="success"
                  disabled={this.state.splitButtonDisabled}
                  onClick={() => this.handleModal("split")}
                >
                  {t("Confirm")}
                </Button>
              </DialogActions>
            </Dialog>

            {/* Dialog Confirmation */}
            <Dialog
              classes={{
                root: classes.modalRoot,
                paper: classes.modal,
              }}
              open={this.state.modalConfirmShow}
              TransitionComponent={Transition}
              keepMounted
              //onClose={() => this.setState({successModal: false})}
              aria-labelledby="classic-modal-slide-title"
              aria-describedby="classic-modal-slide-description"
            >
              {/* Modal Title */}
              <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <h4 className={classes.modalTitle}>
                  {this.state.modalConfirmTitle}
                </h4>
              </DialogTitle>
              {/* Modal Body */}
              <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}
              >
                <GridContainer justify="center">
                  <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                    {/* Are you sure want to {this.state.modalTitle} ? */}
                    {this.state.modalConfirmMsg}
                  </GridItem>
                </GridContainer>
              </DialogContent>
              {/* Modal Action */}
              <DialogActions className={classes.modalFooter}>
                <Button
                  round
                  color="warning"
                  onClick={() => {
                    this.setState({
                      modalConfirmShow: false,
                      modalConfirmTitle: "",
                      modalConfirmMsg: "",
                    });
                  }}
                >
                  NO
                </Button>
                <Button
                  onClick={() => this.handleSubmit()}
                  round
                  color="success"
                >
                  YES
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
}
Payment.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withStyles(styles), withTranslation())(Payment);
